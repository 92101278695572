// PatientDetails.jsx
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import {
  Table,
  Button,
  Space,
  Modal,
  message,
  Tag,
  Row,
  Col,
  Typography,
} from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  LeftOutlined,
  UploadOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import EditPatientModal from './EditPatientModal/EditPatientModal';
import AddPatientModal from './AddPatientModal/AddPatientModal';
import BulkUploadModal from './BulkUploadModal/BulkUploadModal';
import ThemeContext from '../../context/ThemeContext';
import { useAuth } from '../../auth/AuthContext';
import debounce from 'lodash.debounce';

const { confirm } = Modal;
const { Title } = Typography;

const PatientDetails = () => {
  const { themeMode } = useContext(ThemeContext);
  const auth = useAuth();
  const [patientsData, setPatientsData] = useState([]);
  const [totalPatients, setTotalPatients] = useState(0);
  const [updated, setUpdated] = useState(false);
  const [loading, setLoading] = useState(false);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  // Sorting state
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);

  // Filtering state
  const [filters, setFilters] = useState({
    first_name: [],
    last_name: [],
    gender: [],
    email: [],
    phone: [],
    date_of_birth: [],
  });

  // Unique filter values
  const [uniqueValues, setUniqueValues] = useState({
    first_name: [],
    last_name: [],
    gender: [],
    email: [],
    phone: [],
    date_of_birth: [],
  });

  // Edit Modal state
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [selectedPatientId, setSelectedPatientId] = useState(null);
  const [isBulkUploadModalOpen, setIsBulkUploadModalOpen] = useState(false);

  const showBulkUploadModal = () => {
    setIsBulkUploadModalOpen(true);
  };

  const handleBulkUploadCancel = () => {
    setIsBulkUploadModalOpen(false);
  };

  // Debounced fetch to prevent excessive API calls
  const debouncedFetch = debounce(() => {
    fetchPatients();
  }, 300);

  // Fetch unique filter values
  const fetchUniqueValues = async () => {
    try {
      const columnsToFetch = [
        'first_name',
        'last_name',
        'date_of_birth',
        'gender',
        'email',
        'phone',
      ];
      const fetchPromises = columnsToFetch.map((column) =>
        fetch(
          `${process.env.REACT_APP_API_URL}/patients/unique/${column}`,
          {
            headers: {
              Authorization: `Bearer ${auth.user['access_token']}`,
            },
          }
        ).then((response) => {
          if (!response.ok) {
            throw new Error(`Failed to fetch unique values for ${column}`);
          }
          return response.json();
        })
      );

      const results = await Promise.all(fetchPromises);

      const newUniqueValues = {};
      columnsToFetch.forEach((column, index) => {
        newUniqueValues[column] = results[index].unique_values;
      });

      setUniqueValues((prev) => ({
        ...prev,
        ...newUniqueValues,
      }));
    } catch (error) {
      console.error('Error fetching unique values:', error);
      message.error(
        'An error occurred while fetching unique filter values.'
      );
    }
  };

  // Effect to fetch patients data
  useEffect(() => {
    debouncedFetch();

    // Cleanup debounce on unmount
    return () => {
      debouncedFetch.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, sortField, sortOrder, filters]);

  // Effect to fetch unique values on component mount
  useEffect(() => {
    fetchUniqueValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchPatients = async () => {
    setLoading(true);
    try {
      const params = new URLSearchParams();
      params.append('skip', (currentPage - 1) * pageSize);
      params.append('limit', pageSize);

      // Add sorting parameters if any
      if (sortField && sortOrder) {
        params.append('sort_field', sortField);
        params.append('sort_order', sortOrder === 'ascend' ? 'asc' : 'desc');
      }

      // Add filtering parameters if any
      Object.keys(filters).forEach((key) => {
        if (filters[key] && filters[key].length > 0) {
          if (key === 'date_of_birth') {
            // Assuming date_of_birth is a list of exact dates
            filters[key].forEach((date) => {
              params.append('date_of_birth', date);
            });
          } else {
            filters[key].forEach((value) => {
              params.append(key, value);
            });
          }
        }
      });

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/patients?${params.toString()}`,
        {
          headers: {
            Authorization: `Bearer ${auth.user['access_token']}`,
          },
        }
      );

      if (response.ok) {
        const result = await response.json();
        const { data, count } = result.patients;
        setPatientsData(data);
        setTotalPatients(count);
      } else {
        const errorData = await response.json();
        message.error(errorData.detail || 'Failed to fetch patients data');
      }
    } catch (error) {
      console.error('Error fetching patients:', error);
      message.error('An error occurred while fetching patients data');
    } finally {
      setLoading(false);
      setUpdated(false);
    }
  };

  const handleTableChange = (pagination, tableFilters, sorter) => {
    // Handle pagination
    if (pagination.current !== currentPage) {
      setCurrentPage(pagination.current);
    }
    if (pagination.pageSize !== pageSize) {
      setPageSize(pagination.pageSize);
      setCurrentPage(1); // Reset to first page when page size changes
    }

    // Handle sorting
    if (sorter.field) {
      setSortField(sorter.field);
      setSortOrder(sorter.order);
    } else {
      setSortField(null);
      setSortOrder(null);
    }

    // Handle filters
    const updatedFilters = { ...filters };

    // Extract filters from tableFilters
    Object.keys(updatedFilters).forEach((key) => {
      if (
        key === 'gender' ||
        key === 'first_name' ||
        key === 'last_name' ||
        key === 'email' ||
        key === 'phone' ||
        key === 'date_of_birth'
      ) {
        updatedFilters[key] = tableFilters[key] ? tableFilters[key] : [];
      }
    });

    setFilters(updatedFilters);
  };

  const showDeleteConfirm = (patientId, patientName) => {
    confirm({
      title:
        'Are you sure you want to delete this patient? This action will permanently delete the patient data and cannot be undone.',
      content: `Patient Name: ${patientName}`,
      okText: 'Confirm Permanent Deletion',
      okType: 'primary',
      cancelText: 'No',
      centered: true,
      className: themeMode === 'dark' ? 'dark-modal' : 'light-modal',
      onOk() {
        deletePatient(patientId);
      },
      onCancel() {
        // Do nothing on cancel
      },
    });
  };

  const deletePatient = async (patientId) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/patients/${patientId}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${auth.user['access_token']}`,
          },
        }
      );

      if (response.ok) {
        const result = await response.json();
        if (result.message) {
          message.success(result.message);
          fetchPatients(); // Refresh data
          fetchUniqueValues(); // Refresh unique filter values
        } else {
          message.success('Patient deleted successfully');
          fetchPatients(); // Refresh data
          fetchUniqueValues(); // Refresh unique filter values
        }
      } else {
        const errorData = await response.json();
        message.error(errorData.detail || 'Failed to delete patient');
      }
    } catch (error) {
      console.error('Error deleting patient:', error);
      message.error('An error occurred while deleting the patient');
    } finally {
      setLoading(false);
    }
  };

  const openEditModal = (patientId) => {
    setSelectedPatientId(patientId);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setSelectedPatientId(null);
    setIsEditModalOpen(false);
    fetchPatients(); // Refresh data after editing
    fetchUniqueValues(); // Refresh unique filter values
  };

  useEffect(() => {
    if (updated) {
      fetchPatients();
      fetchUniqueValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated]);

  // Calculate total width of columns
  const totalWidth = 150 + 150 + 150 + 100 + 250 + 150 + 100; // Sum of all column widths
  // Define table columns with sorting and multi-select filtering
  const columns = [
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      filters: uniqueValues.first_name.map((value) => ({
        text: value,
        value,
      })),
      filteredValue:
        filters.first_name.length > 0 ? filters.first_name : null,
      onFilter: (value, record) => record.first_name === value,
      width: 150,
      ellipsis: true,
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      filters: uniqueValues.last_name.map((value) => ({
        text: value,
        value,
      })),
      filteredValue:
        filters.last_name.length > 0 ? filters.last_name : null,
      onFilter: (value, record) => record.last_name === value,
      width: 150,
      ellipsis: true,
    },
    {
      title: 'Date of Birth',
      dataIndex: 'date_of_birth',
      key: 'date_of_birth',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      filters: uniqueValues.date_of_birth.map((value) => ({
        text: value,
        value,
      })),
      filteredValue:
        filters.date_of_birth.length > 0 ? filters.date_of_birth : null,
      onFilter: (value, record) => record.date_of_birth === value,
      render: (text) =>
        text ? new Date(text).toLocaleDateString() : '',
      width: 150,
      ellipsis: true,
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      filters: uniqueValues.gender.map((value) => {
        let text = value;
        switch (value) {
          case 'M':
            text = 'Male';
            break;
          case 'F':
            text = 'Female';
            break;
          case 'O':
            text = 'Other';
            break;
          default:
            break;
        }
        return { text, value };
      }),
      filteredValue:
        filters.gender.length > 0 ? filters.gender : null,
      onFilter: (value, record) => record.gender === value,
      render: (gender) => {
        let color;
        let text;
        switch (gender) {
          case 'M':
            color = 'blue';
            text = 'Male';
            break;
          case 'F':
            color = 'pink';
            text = 'Female';
            break;
          case 'O':
            color = 'green';
            text = 'Other';
            break;
          default:
            color = 'default';
            text = gender;
        }
        return <Tag color={color}>{text}</Tag>;
      },
      width: 100,
      ellipsis: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      filters: uniqueValues.email.map((value) => ({
        text: value,
        value,
      })),
      filteredValue:
        filters.email.length > 0 ? filters.email : null,
      onFilter: (value, record) => record.email === value,
      render: (email) => (
        <span style={{ color: '#64B441' }}>{email}</span>
      ),
      width: 250,
      ellipsis: true,
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      filters: uniqueValues.phone.map((value) => ({
        text: value,
        value,
      })),
      filteredValue:
        filters.phone.length > 0 ? filters.phone : null,
      onFilter: (value, record) => record.phone === value,
      width: 150,
      ellipsis: true,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button
            icon={<EditOutlined />}
            onClick={() => openEditModal(record._id)}
          />
          <Button
            icon={<DeleteOutlined />}
            danger
            onClick={() =>
              showDeleteConfirm(
                record._id,
                `${record.first_name} ${record.last_name}`
              )
            }
          />
        </Space>
      ),
      width: 100,
      fixed: 'right',
    },
  ];

  return (
    <div style={{ padding: '24px' }}>
      <Row>
        <Col lg={12} md={12} sm={24} xs={24}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Link
              to="/synopsis"
              style={{
                fontSize: '20px',
                fontWeight: 600,
                display: 'flex',
                alignItems: 'center',
                textDecoration: 'none',
              }}
            >
              <LeftOutlined style={{ marginRight: '8px' }} />
            </Link>
            <Title level={3} style={{ marginTop: '10px' }}>
              Patient Management
            </Title>
          </div>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Space
            style={{
              marginBottom: 16,
              float: 'right',
              marginTop: '10px',
            }}
          >
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setIsAddModalOpen(true)}
              style={{ marginRight: '15px' }}
            >
              Add a Patient
            </Button>
            <Button
              type="primary"
              icon={<UploadOutlined />}
              onClick={showBulkUploadModal}
            >
              Bulk Upload Patient
            </Button>
          </Space>
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={patientsData}
        rowKey="_id"
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: totalPatients,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100'],
          showQuickJumper: true,
        }}
        style={{ width: '100%' }}
        onChange={handleTableChange}
        scroll={{ x: totalWidth }} // Set to total width of columns
      />

      {/* Edit Patient Modal */}
      {isEditModalOpen && (
        <EditPatientModal
          open={isEditModalOpen}
          setOpen={setIsEditModalOpen}
          patientId={selectedPatientId}
          setUpdated={setUpdated}
        />
      )}

      {isAddModalOpen && (
        <AddPatientModal
          open={isAddModalOpen}
          setOpen={setIsAddModalOpen}
          setUpdated={setUpdated}
        />
      )}

      {/* Bulk Upload Modal */}
      <BulkUploadModal
        visible={isBulkUploadModalOpen}
        onCancel={handleBulkUploadCancel}
        onUploadSuccess={setUpdated}
        apiUrl={process.env.REACT_APP_API_URL}
        authToken={auth.user['access_token']}
        templateDownloadUrl={`${process.env.REACT_APP_API_URL}/patients/download/bulk_upload_test_data`}
      />
    </div>
  );
};

export default PatientDetails;
