// src/components/LinkedInLogin/LinkedInCallback.tsx
import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../auth/AuthContext';

const LinkedInCallback = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    const token = searchParams.get('token');

    if (token) {
      // Store the token and authenticate the user
      localStorage.setItem('user', JSON.stringify({ access_token: token }));
      auth.fetchUserDetails(token).then(() => {
        auth.setUser({ access_token: token, first_login: false, token_type: "bearer" });
        navigate('/synopsis'); // Redirect to the dashboard or another protected route
      });
    }
  }, [searchParams, navigate, auth]);

  return <div>Loading...</div>;
};

export default LinkedInCallback;
