import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, DatePicker, Radio, Button, message, Spin, Row, Col,Typography } from 'antd';
import {marked} from 'marked';
import DOMPurify from 'dompurify';

const FullScreenModal = ({open, setOpen, text}) => {
    const [modalContent, setModalContent] = useState(null)
    const handleOk = () => {
        setOpen(false);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const markdownToHtml = (markdown) => {
        const rawHtml = marked.parse(markdown); // Adjusted to use `parse` method
        return DOMPurify.sanitize(rawHtml);// Sanitize HTML to prevent XSS attacks
    };

    useEffect(() => {
        if (text) {
            setModalContent(text);
        }
    }, [text]);

    
    return (
        <Modal
          title={"Fullscreen View"
            // <Row>
            //   <Col lg={8} md={8} sm={24} xs={24}>                 
            //     <Typography.Title level={5} style={{ margin: 0 }}>Fullscreen View</Typography.Title>
            //   </Col>
            //   <Col lg={15} md={15} sm={24} xs={24} style={{ textAlign: "right" }}>
            //     <IconButtonGroup 
            //       text={assessment == null ? "" :assessment} 
            //       feature={{ edit: false, copy: false, download: true, email: true }} 
            //       fileId={fileId} 
            //       contentType={"assessment_report"}
            //     />
            //   </Col>
            // </Row>
          }
          open={open}
          onOk={handleOk}
          onCancel={handleCancel}
          maskClosable={false}
          centered
          destroyOnClose={false}
          width={1000}
          bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}
        >    
         {modalContent ? 
         <div className="message-content" dangerouslySetInnerHTML={{ __html: markdownToHtml(modalContent) }} /> : 
          <div
            style={{
                // height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: "20px"
            }}
            >
            <Spin size="large" />
          </div>}
        </Modal>
    )
}

export default FullScreenModal