import React, { useState } from 'react';
import { Modal, Form, Input, DatePicker, Radio, Button, message } from 'antd';
import { useAuth } from '../../../auth/AuthContext';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const AddPatientModal = ({ open, setOpen, fetchPatients, setPatientId, setPatientName, setUpdated }) => {
    const auth = useAuth();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const userDetails = localStorage.getItem("userDetails");
    const user = userDetails && JSON.parse(userDetails);

    const handleOk = () => {
        form.validateFields()
        .then(async(values) => {
            setLoading(true);

            // Trim all string values to remove extra spaces at start or end
            const formattedValues = {
                ...values,
                first_name: values.first_name.trim(),
                last_name: values.last_name.trim(),
                email: values.email.trim(),
                phone: values.phone? values.phone.trim(): null,  // Phone input
                date_of_birth: values.date_of_birth? values.date_of_birth.format('YYYY-MM-DD') : null,
            };

            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/patients/create`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${auth.user['access_token']}`,
                    },
                    method: 'POST',
                    body: JSON.stringify(formattedValues),
                });

                if (response.ok) {
                    const result = await response.json();
                    setUpdated && setUpdated(true);
                    fetchPatients && await fetchPatients();
                    setPatientId && setPatientId(result.patient_id)
                    setPatientName && setPatientName(`${formattedValues.first_name} ${formattedValues.last_name}`); // Update patient name in parent
                    message.success('Patient added successfully');
                    form.resetFields(); // Reset the form fields after submission
                } else {
                    message.error('Failed to add patient information');
                }

                setOpen(false); // Close the modal
            } catch (error) {
                message.error('Failed to add patient');
                console.error('Error submitting patient data:', error);
            } finally {
                setLoading(false);
            }
        })
        .catch(info => {
            console.error('Validate Failed:', info);
            setLoading(false);
        });
    };

    const handleCancel = () => {
        setOpen(false);
        form.resetFields();
    };

    return (
        <Modal
            title="Add a New Patient"
            open={open}
            onOk={handleOk}
            onCancel={handleCancel}
            maskClosable={false}
            centered
            footer={[
                <Button key="cancel" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
                    Save
                </Button>,
            ]}
        >
        <Form
            form={form}
            layout="vertical"
            name="add_patient_form"
        >
            <Form.Item
                name="first_name"
                label="First Name"
                rules={[{ required: true, message: 'Please input the first name!' }]}
            >
                <Input placeholder="Enter first name" />
            </Form.Item>

            <Form.Item
                name="last_name"
                label="Last Name"
                rules={[{ required: true, message: 'Please input the last name!' }]}
            >
                <Input placeholder="Enter last name" />
            </Form.Item>

            <Form.Item
                name="date_of_birth"
                label="Date of Birth"
                // rules={[{ required: true, message: 'Please select the date of birth!' }]}
            >
                <DatePicker style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item
                name="gender"
                label="Gender"
                rules={[{ required: true, message: 'Please select the gender!' }]}
            >
                <Radio.Group>
                    <Radio value="M">Male</Radio>
                    <Radio value="F">Female</Radio>
                    <Radio value="O">Other</Radio>
                </Radio.Group>
            </Form.Item>

            <Form.Item
                name="email"
                label="Email"
                rules={[{ required: true, type: 'email', message: 'Please input a valid email!' }]}
            >
                <Input placeholder="Enter email" />
            </Form.Item>

            <Form.Item
                name="phone"
                label="Phone"
                // rules={[{ required: true, message: 'Please input the phone number!' }]}
            >
                <PhoneInput
                    country={'us'}  // You can set a default country code, change as needed
                    value={form.getFieldValue('phone')} // Sync value with form
                    onChange={(value) => form.setFieldsValue({ phone: value.trim() })} // Trim phone number
                    inputStyle={{ width: '100%' }}  // Ensure full width input
                />
            </Form.Item>
        </Form>
        </Modal>
    );
};

export default AddPatientModal;
