import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Input,
  message,
  Row,
  Col,
  Select,
  Typography,
  Spin,
  Divider,
  Badge,
  Tooltip,
  Progress,
  Space,
} from 'antd';
import {
  AudioOutlined,
  DeleteOutlined,
  UserAddOutlined,
  XFilled,
  LoadingOutlined,
  PauseCircleOutlined,
} from '@ant-design/icons';
import { LiveAudioVisualizer } from 'react-audio-visualize';
import AddPatientModal from '../../../PatientDetails/AddPatientModal/AddPatientModal';
import { useAuth } from '../../../../auth/AuthContext';

const { Title, Text } = Typography;

const AudioRecorder = ({
  setOpen,
  setSelectedId,
  fetchData,
  setNewSubmission,
  setSelectedLanguage,
}) => {
  const auth = useAuth();
  const navigate = useNavigate();

  // State variables
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [recordingState, setRecordingState] = useState('inactive'); // 'inactive', 'recording', 'paused'
  const [audioURL, setAudioURL] = useState(null);
  const [audioBlob, setAudioBlob] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [patients, setPatients] = useState([]);
  const [patientId, setPatientId] = useState('');
  const [patientName, setPatientName] = useState(null);
  const [language, setLanguage] = useState('english');
  const [patientModalVisible, setPatientModalVisible] = useState(false);
  const [selectedTemplateName, setSelectedTemplateName] = useState('');
  const [blobName, setBlobName] = useState(null);
  const [fileId, setFileId] = useState(null);
  const [micInfo, setMicInfo] = useState(''); // Microphone Information
  const [bandwidthStatus, setBandwidthStatus] = useState('unknown'); // 'low', 'medium', 'high', 'offline', 'unknown'
  const [downloadSpeed, setDownloadSpeed] = useState(null); // in Mbps
  const [isOnline, setIsOnline] = useState(navigator.onLine); // Online Status
  const [deleteLoading, setDeleteLoading] = useState(false);
  const audioChunksRef = useRef([]);

  // New state variables for recording duration and note
  const [recordingDuration, setRecordingDuration] = useState(0); // in seconds
  const recordingIntervalRef = useRef(null);
  const [autoStopNote, setAutoStopNote] = useState('');

  // References for chunk upload queue and upload state
  const chunkUploadQueue = useRef([]);
  const isUploadingRef = useRef(false);

  // Fetch Patients and Templates on Mount
  useEffect(() => {
    setPageLoading(true);
    fetchTemplates();
    fetchPatients();
  }, []);

  // Fetch Patients Function
  const fetchPatients = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/patients`, {
        headers: {
          Authorization: `Bearer ${auth.user['access_token']}`,
        },
        method: 'GET',
      });

      if (response.ok) {
        const result = await response.json();
        const options = result.patients.data.map((item) => ({
          label: item.first_name + ' ' + item.last_name,
          value: item._id,
        }));
        setPatients(options);
      } else {
        message.error('Failed to fetch patient information');
        setOpen(false);
      }
    } catch (error) {
      console.error('Error fetching patient information', error);
      message.error('Error fetching patient information');
      setOpen(false);
    }
    setPageLoading(false);
  };

  // Fetch Templates Function
  const fetchTemplates = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/templates`, {
        headers: {
          Authorization: `Bearer ${auth.user['access_token']}`,
        },
        method: 'GET',
      });

      if (response.ok) {
        const result = await response.json();

        // Fetch user preferences
        const userResponse = await fetch(`${process.env.REACT_APP_API_URL}/get_user`, {
          headers: {
            Authorization: `Bearer ${auth.user['access_token']}`,
          },
          method: 'GET',
        });

        if (userResponse.ok) {
          const user = await userResponse.json();
          if (user.preferred_template_id) {
            const templateName =
              result.data.filter((r) => r._id === user.preferred_template_id)[0]?.name || '';
            setSelectedTemplateName(templateName);
          } else if (result.data.length > 0) {
            setSelectedTemplateName(result.data[0].name);
          }
        } else if (result.data.length > 0) {
          setSelectedTemplateName(result.data[0].name);
        }
      } else {
        message.error('Failed to fetch summary templates');
      }
    } catch (error) {
      console.error('Error fetching summary templates', error);
      message.error('Error fetching summary templates');
    }
  };

  // Function to get microphone information
  const getMicrophoneInfo = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const audioDevices = devices.filter((device) => device.kind === 'audioinput');
      if (audioDevices.length > 0) {
        return audioDevices[0].label || 'Default Microphone';
      }
      return 'No Microphone Found';
    } catch (error) {
      console.error('Error fetching microphone information', error);
      return 'Unknown Microphone';
    }
  };

  // Function to perform a basic speed test
  const performSpeedTest = async () => {
    const imageUrl = 'https://via.placeholder.com/1000x1000.png'; // A small image file
    const startTime = new Date().getTime();
    try {
      const response = await fetch(imageUrl + `?cacheBuster=${startTime}`);
      const blob = await response.blob();
      const endTime = new Date().getTime();
      const duration = (endTime - startTime) / 1000; // seconds
      const bitsLoaded = blob.size * 8;
      const speedBps = bitsLoaded / duration;
      const speedMbps = (speedBps / (1024 * 1024)).toFixed(2);
      setDownloadSpeed(speedMbps);
      return speedMbps;
    } catch (error) {
      console.error('Speed test failed:', error);
      setDownloadSpeed(null);
      return null;
    }
  };

  // Function to detect bandwidth
  const detectBandwidth = async () => {
    // Check if online
    if (!navigator.onLine) {
      setIsOnline(false);
      setBandwidthStatus('offline');
      return;
    }

    // Listen to online/offline events
    const handleOnline = () => {
      setIsOnline(true);
      message.info('You are back online.');
      detectBandwidth(); // Re-run detection
    };

    const handleOffline = () => {
      setIsOnline(false);
      setBandwidthStatus('offline');
      message.warning('You have lost internet connection.');
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Network Information API
    if ('connection' in navigator) {
      const connection =
        navigator.connection || navigator.mozConnection || navigator.webkitConnection;
      if (connection) {
        const updateBandwidthStatus = () => {
          const effectiveType = connection.effectiveType;
          let status = 'unknown';
          switch (effectiveType) {
            case 'slow-2g':
            case '2g':
            case '3g':
              status = 'low';
              break;
            case '4g':
            case '5g':
              status = 'high';
              break;
            default:
              status = 'unknown';
          }
          setBandwidthStatus(status);
        };

        // Initial check
        updateBandwidthStatus();

        // Listen for changes
        connection.addEventListener('change', updateBandwidthStatus);
      }
    }

    // Perform a basic speed test
    const speedMbps = await performSpeedTest();
    if (speedMbps) {
      if (speedMbps < 1) {
        setBandwidthStatus('low');
      } else if (speedMbps >= 1 && speedMbps < 5) {
        setBandwidthStatus('medium');
      } else {
        setBandwidthStatus('high');
      }
    }

    // Cleanup event listeners when recording stops
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  };

  // Handle Record Button Click
  const startRecording = async () => {
    if (blobName) {
      message.error('Please submit or delete the existing recording before starting a new one.');
      return;
    }

    if (recordingState === 'recording') {
      // Already recording
      return;
    }

    if (!patientName?.trim()) {
      message.error('Please select a patient first!');
      return;
    }

    let _blobName;
    if (!blobName) {
      // Call empty_audio_upload_to_blob endpoint
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append('patient_id', patientId);
        formData.append('language', language);

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/v3/files/audio/upload`,
          {
            headers: {
              Authorization: `Bearer ${auth.user['access_token']}`,
            },
            method: 'POST',
            body: formData,
          }
        );

        if (response.ok) {
          const result = await response.json();
          setBlobName(result.file_path); // Adjust according to your backend response
          _blobName = result.file_path;
          setFileId(result.file_id); // If needed
          // message.success('Empty blob created successfully');
        } else {
          message.error('Failed to start recording');
          return;
        }
      } catch (error) {
        console.error('Error start recording', error);
        message.error('Error start recording');
        return;
      } finally {
        setLoading(false);
      }
    }

    // Start recording
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      setMediaRecorder(recorder);
      audioChunksRef.current = []; // Clear previous chunks

      recorder.ondataavailable = (event) => {
        const chunk = event.data;
        chunkUploadQueue.current.push(chunk);
        processUploadQueue(_blobName); // Start processing the queue
        audioChunksRef.current.push(chunk); // For local playback
      };

      recorder.onstop = () => {
        // Create Blob from chunks
        const blob = new Blob(audioChunksRef.current, { type: 'audio/webm' }); // Adjust the MIME type if needed
        const url = URL.createObjectURL(blob);
        setAudioURL(url);
        setAudioBlob(blob);
      };

      // Start the recorder with timeslice to get data in chunks
      recorder.start(1000); // Get data every second
      setRecordingState('recording');
      message.info('Recording started!');

      // Start recording duration timer
      setRecordingDuration(0); // Reset duration
      recordingIntervalRef.current = setInterval(() => {
        setRecordingDuration((prevDuration) => prevDuration + 1);
      }, 1000);

      // Get Microphone Info
      const deviceInfo = await getMicrophoneInfo();
      setMicInfo(deviceInfo);

      // Start Bandwidth Detection
      detectBandwidth();
    } catch (error) {
      console.error('Error accessing the microphone', error);
      message.error('Failed to start recording');
    }
  };

  // Process upload queue function
  const processUploadQueue = async (_blobName) => {
    if (isUploadingRef.current) {
      return;
    }
    if (chunkUploadQueue.current.length === 0) {
      return;
    }

    isUploadingRef.current = true;
    const chunk = chunkUploadQueue.current.shift();

    try {
      const formData = new FormData();
      formData.append('blob_name', _blobName || blobName);
      formData.append('audio_chunk', chunk, 'chunk.webm');

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/files/audio/upload/chunk`,
        {
          headers: {
            Authorization: `Bearer ${auth.user['access_token']}`,
          },
          method: 'POST',
          body: formData,
        }
      );

      if (response.ok) {
        console.log('Chunk uploaded successfully');
      } else {
        console.error('Failed to upload chunk');
        message.error('Failed to upload audio chunk');
        // Optionally retry or handle error
      }
    } catch (error) {
      console.error('Error uploading chunk', error);
      // message.error('Error uploading audio chunk');
      // Optionally retry or handle error
    } finally {
      isUploadingRef.current = false;
      processUploadQueue(_blobName);
    }
  };

  // Pause Recording
  const pauseRecording = () => {
    if (mediaRecorder && mediaRecorder.state === 'recording') {
      mediaRecorder.pause();
      setRecordingState('paused');
      message.info('Recording paused!');
      // Pause recording duration timer
      clearInterval(recordingIntervalRef.current);
    }
  };

  // Resume Recording
  const resumeRecording = () => {
    if (mediaRecorder && mediaRecorder.state === 'paused') {
      mediaRecorder.resume();
      setRecordingState('recording');
      message.info('Recording resumed!');
      // Resume recording duration timer
      recordingIntervalRef.current = setInterval(() => {
        setRecordingDuration((prevDuration) => prevDuration + 1);
      }, 1000);
    }
  };

  // Stop Recording
  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      mediaRecorder.stream.getTracks().forEach((track) => track.stop()); // Stop all tracks
      setRecordingState('inactive');
      message.info('Recording stopped!');
      // Stop recording duration timer
      clearInterval(recordingIntervalRef.current);
    }
  };

  // Delete Recording
  const deleteRecording = async () => {
    if (!fileId) {
      message.error('No interview to delete');
      return;
    }

    try {
      setDeleteLoading(true); // Set loading to true during the API call
      const formData = new FormData();
      formData.append('file_id', fileId);

      // Make the API call to clear the blob
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/files/audio/upload/chunk/clear`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${auth.user['access_token']}`, // Authorization header with the user token
          },
          body: formData,
        }
      );

      if (response.ok) {
        const result = await response.json();
        message.success('Recording deleted successfully');

        // Reset state after successful deletion
        setMediaRecorder(null);
        setBlobName(null);
        setFileId(null);
        setRecordingState('inactive');
        audioChunksRef.current = [];
        setAudioURL(null);
        setAudioBlob(null);
        setMicInfo(''); // Reset mic info
        setBandwidthStatus('unknown'); // Reset bandwidth status
        setDownloadSpeed(null); // Reset download speed
        setAutoStopNote(''); // Reset note
        setRecordingDuration(0); // Reset recording duration
      } else {
        const errorResult = await response.json();
        message.error(errorResult.detail || 'Failed to delete recording');
      }
    } catch (error) {
      console.error('Error deleting recording:', error);
      message.error('Error deleting recording');
    } finally {
      setDeleteLoading(false); // Stop loading after the API call
    }
  };

  // Submit Recording
  const submitRecording = async () => {
    if (!blobName) {
      message.error('No recording to submit');
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append('file_id', fileId);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/files/audio/toggle_is_deleted`,
        {
          headers: {
            Authorization: `Bearer ${auth.user['access_token']}`,
          },
          method: 'POST',
          body: formData,
        }
      );

      if (response.ok) {
        const result = await response.json();
        await fetchData();
        setNewSubmission(true);
        setSelectedId(fileId);
        // Reset after successful submission
        setMediaRecorder(null);
        audioChunksRef.current = [];
        setBlobName(null);
        setFileId(null);
        setRecordingState('inactive');
        setAudioURL(null);
        setAudioBlob(null);
        setMicInfo('');
        setBandwidthStatus('unknown');
        setDownloadSpeed(null);
        setAutoStopNote('');
        setRecordingDuration(0);
        setOpen(false);
        message.success('Interview submitted successfully');
      } else {
        message.error('Failed to submit interview');
      }
    } catch (error) {
      console.error('Error submitting interview', error);
      message.error('Error submitting interview');
    } finally {
      setLoading(false);
    }
  };

  // Cleanup on Unmount
  useEffect(() => {
    return () => {
      if (mediaRecorder) {
        mediaRecorder.stream.getTracks().forEach((track) => track.stop());
      }
      clearInterval(recordingIntervalRef.current);
    };
  }, [mediaRecorder]);

  // Handle recording duration and auto-stop after 2.5 hours
  useEffect(() => {
    if (recordingDuration >= 9000) {
      // Stop the recording, show message, speak the alert, write note
      stopRecording();

      // Show message
      message.info(
        'Your interview has reached the maximum duration of 2.5 hours and has been stopped automatically.'
      );

      // Use text-to-speech to alert the doctor
      const utterance = new SpeechSynthesisUtterance(
        'You have reached the maximum duration of two and a half hours, and the recording has been stopped.'
      );
      window.speechSynthesis.speak(utterance);

      // Write note
      setAutoStopNote(
        'Recording automatically stopped after reaching maximum duration of 2.5 hours.'
      );
    }
  }, [recordingDuration]);

  // Handle Patient Selection Change
  const handleChange = (value) => {
    setPatientId(value);
    const selectedOption = patients.find((option) => option.value === value);
    if (selectedOption) {
      setPatientName(selectedOption.label);
    } else {
      setPatientName(null);
    }
  };

  // Function to render bandwidth indicator
  const renderBandwidthIndicator = () => {
    if (!isOnline) {
      return (
        <Tooltip title="You are offline">
          <Badge status="error" text="Offline" />
        </Tooltip>
      );
    }

    switch (bandwidthStatus) {
      case 'low':
        return (
          <Tooltip title="Low Internet Bandwidth">
            <Badge status="error" text="Low Bandwidth" />
          </Tooltip>
        );
      case 'medium':
        return (
          <Tooltip title="Medium Internet Bandwidth">
            <Badge status="warning" text="Medium Bandwidth" />
          </Tooltip>
        );
      case 'high':
        return (
          <Tooltip title="High Internet Bandwidth">
            <Badge status="success" text="High Bandwidth" />
          </Tooltip>
        );
      default:
        return (
          <Tooltip title="Bandwidth Status Unknown">
            <Badge status="default" text="Unknown" />
          </Tooltip>
        );
    }
  };

  return (
    <>
      {pageLoading ? (
        <div
          style={{
            height: '90vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <Row
          style={{
            height: '85vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Col xs={0} sm={0} md={0} lg={7}></Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={10}
            style={{
              padding: '15px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <div>
              <Button
                type="primary"
                shape="round"
                icon={<UserAddOutlined />}
                style={{ width: '100%', marginBottom: '20px' }}
                size="large"
                onClick={() => setPatientModalVisible(true)}
              >
                Add a New Patient
              </Button>
              <Divider>OR</Divider>
              <Typography.Title level={5}>Selected Summary Template</Typography.Title>
              <Input
                placeholder="Summary Template"
                value={selectedTemplateName}
                readOnly
                disabled
              />
              <small>
                <i>
                  <b style={{ color: 'red' }}>Note: </b>To change the summary template please go to{' '}
                  <b
                    onClick={() => navigate('/configureTemplate')}
                    style={{
                      textDecoration: 'underline',
                      color: '#0074D9',
                      cursor: 'pointer',
                    }}
                  >
                    Configure Template
                  </b>
                  .
                </i>{' '}
              </small>
              <Typography.Title level={5}>Select a Patient</Typography.Title>
              <Select
                allowClear={true}
                showSearch
                placeholder="Select a patient"
                optionFilterProp="label"
                value={patientId}
                onChange={(v) => handleChange(v)}
                style={{ width: '100%', marginBottom: '15px' }}
                options={patients}
              />
              {patientId && (
                <>
                  <Typography.Title level={5}>Select Interview Language</Typography.Title>
                  <Select
                    allowClear={true}
                    showSearch
                    placeholder="Select Interview language"
                    optionFilterProp="label"
                    value={language}
                    onChange={(v) => {
                      setLanguage(v);
                      if (v === 'english') {
                        setSelectedLanguage('en');
                      } else if (v === 'hindi') {
                        setSelectedLanguage('hindi');
                      } else if (v === 'greek') {
                        setSelectedLanguage('el');
                      } else {
                        setSelectedLanguage('en'); // Default to English if none selected
                      }
                    }}
                    style={{ width: '100%', marginBottom: '15px' }}
                    options={[
                      { label: 'English', value: 'english' },
                      { label: 'Hindi', value: 'hindi' },
                      { label: 'Greek', value: 'greek' },
                    ]}
                  />
                </>
              )}
            </div>

            {/* Display Microphone and Bandwidth Status */}
            {recordingState !== 'inactive' && (
              <div style={{ marginTop: '30px' }}>
                <Space direction="vertical" size="small" style={{ width: '100%' }}>
                  <div>
                    <Text strong>Microphone:</Text> {micInfo || 'Not Selected'}
                  </div>

                  <Row>
                    <Col xs={24} sm={24} md={24} lg={12}>
                      <div>
                        <Text strong>Bandwidth Status:</Text> {renderBandwidthIndicator()}
                      </div>
                      {downloadSpeed && (
                        <div>
                          <Text strong>Download Speed:</Text> {downloadSpeed} Mbps
                          <Progress
                            percent={Math.min((downloadSpeed / 100) * 100, 100)}
                            size="small"
                            status="active"
                            style={{ marginTop: '5px' }}
                          />
                        </div>
                      )}
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12}>
                      {/* Display Recording Duration */}
                      <div>
                        <Text strong>Recording Duration:</Text>{' '}
                        {new Date(recordingDuration * 1000).toISOString().substr(11, 8)}
                      </div>
                    </Col>
                  </Row>
                </Space>
              </div>
            )}

            {/* Recording Controls */}
            <div style={{ textAlign: 'center', marginTop: '30px' }}>
              {recordingState === 'inactive' && (
                <>
                  <Button
                    icon={<AudioOutlined style={{ fontSize: '20px' }} />}
                    onClick={startRecording}
                    type="primary"
                    style={{
                      height: '40px',
                      width: '40px',
                      borderRadius: '50%',
                      padding: '10px',
                      marginBottom: '15px',
                    }}
                    disabled={loading || blobName !== null} // Disable button when loading or when blobName exists
                  />
                  <br />
                  <div>
                    <small>
                      <b style={{ color: 'red' }}>Note: </b>Recording will automatically stop after reaching
                      maximum duration of <b>2.5 hours</b>.
                    </small>
                  </div>
                </>
              )}
              {recordingState === 'recording' && (
                <>
                  <Button
                    icon={<PauseCircleOutlined style={{ fontSize: '20px' }} />}
                    onClick={pauseRecording}
                    type="primary"
                    style={{
                      height: '40px',
                      width: '40px',
                      borderRadius: '50%',
                      padding: '10px',
                      backgroundColor: '#f9ae16',
                    }}
                  />
                  <Button
                    icon={<XFilled style={{ fontSize: '20px' }} />}
                    onClick={stopRecording}
                    type="primary"
                    danger
                    style={{
                      marginLeft: '10px',
                      height: '40px',
                      width: '40px',
                      borderRadius: '50%',
                      padding: '10px',
                    }}
                  />
                </>
              )}
              {recordingState === 'paused' && (
                <>
                  <Button
                    icon={<AudioOutlined style={{ fontSize: '20px' }} />}
                    onClick={resumeRecording}
                    type="primary"
                    style={{
                      height: '40px',
                      width: '40px',
                      borderRadius: '50%',
                      padding: '10px',
                    }}
                  />
                  <Button
                    icon={<XFilled style={{ fontSize: '20px' }} />}
                    onClick={stopRecording}
                    type="primary"
                    danger
                    style={{
                      marginLeft: '10px',
                      height: '40px',
                      width: '40px',
                      borderRadius: '50%',
                      padding: '10px',
                    }}
                  />
                </>
              )}
            </div>

            {/* Audio Visualizer */}
            {mediaRecorder && recordingState !== 'inactive' && (
              <div style={{ textAlign: 'center', marginTop: '30px' }}>
                <LiveAudioVisualizer
                  mediaRecorder={mediaRecorder}
                  width={600} // Adjust as needed
                  height={100}
                />
              </div>
            )}

            {/* Audio Playback and Deletion */}
            <div style={{ textAlign: 'center', marginTop: '30px' }}>
              {audioURL && (
                <div
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <audio src={audioURL} controls />
                  <Button
                    type="primary"
                    onClick={deleteRecording}
                    icon={deleteLoading ? <LoadingOutlined /> : <DeleteOutlined />}
                    danger
                    style={{ marginLeft: '10px' }}
                  />
                </div>
              )}
            </div>

            {/* Submit Recording */}
            {blobName && recordingState === 'inactive' && (
              <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <Button type="primary" onClick={submitRecording} loading={loading}>
                  Submit Recording
                </Button>
              </div>
            )}
          </Col>
          <Col xs={0} sm={0} md={0} lg={7}></Col>
        </Row>
      )}
      <AddPatientModal
        open={patientModalVisible}
        setOpen={setPatientModalVisible}
        fetchPatients={fetchPatients}
        setPatientId={setPatientId}
        setPatientName={setPatientName}
      />
    </>
  );
};

export default AudioRecorder;
