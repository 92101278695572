// src/pages/VerificationAwaiting.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { Layout, Row, Col, Typography, Card } from 'antd';
import AppFooter from '../../AppFooter/AppFooter';
import logo from '../../../assets/images/favicon.png';
import fullLogo from '../../../assets/images/logo-no-background.png';

const { Content } = Layout;
const { Title, Text } = Typography;

const VerificationAwaiting = () => {
  return (
    <Layout style={{ minHeight: '100vh', background: "rgb(255,255,255)", background: "linear-gradient(207deg, rgba(110,168,255,1) 0%, rgba(255,255,255,1) 20%, rgba(255,255,255,1) 80%, rgba(110,168,255,1) 100%)" }}>
      <Content style={{ padding: '0', textAlign: 'center' }}>
        <Row>
          <Col span={24} style={{ textAlign: "left", padding: "20px 30px" }}>
            <img src={fullLogo} alt="Sporo Health Logo" width={'150px'} />
          </Col>
        </Row>
        <Row justify="center" align="middle">
          <Col lg={7} md={7} sm={0} xs={0}></Col>
          <Col lg={10} md={10} sm={24} xs={24} style={{ padding: '20px', paddingTop: '10vh' }}>
            <Card style={{ padding: '0px 10px 0px 10px', border: "none", backgroundColor: 'transparent' }}>
              <Title style={{ marginTop: '-10px', marginBottom: '50px', color: '#146ef5' }} level={2}>
                Thank You for Signing Up!
              </Title>
              <Title level={3}>Account Verification in Progress</Title>
              <Text>
                Sporo Health Team will verify your account. Please stay tuned, or reach out to <a href="mailto:contact@sporohealth.com">contact@sporohealth.com</a>.
              </Text>
              <Text style={{ display: 'block', marginTop: '20px' }}>
                <Link to="/">Return to Home</Link>
              </Text>
            </Card>
          </Col>
          <Col lg={7} md={7} sm={0} xs={0}></Col>
        </Row>
        <div style={{ position: "fixed", bottom: 10, width: "100%" }}>
          <AppFooter />
        </div>
      </Content>
    </Layout>
  );
};

export default VerificationAwaiting;
