import React from 'react';
import { Modal } from 'antd';
import './RecordingModal.css';
import AudioRecorder from './AudioRecorder/AudioRecorder';

const RecordingModal = ({open, setOpen, setSelectedId, fetchData, setNewSubmission, setSelectedLanguage}) => {
    const handleOk = () => {
        setOpen(false);
    };

    const handleCancel = () => {
        setOpen(false);
    };
    return (
        <div className={"recording-modal"} >
        <Modal
            maskClosable={false}
            destroyOnClose={true}
            title="Record Conversation"
            open={open}
            onOk={handleOk}
            onCancel={handleCancel}
            width="100%"
            centered
            footer={null}
            className={"recording-modal"} 
        >
            <AudioRecorder setOpen={setOpen} setSelectedId={setSelectedId} fetchData={fetchData} setNewSubmission={setNewSubmission} setSelectedLanguage={setSelectedLanguage} />
        
        </Modal>
        </div>
    )
}

export default RecordingModal;