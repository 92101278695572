// import React, { useState, useEffect } from 'react';
// import { Button } from 'antd';
// import { PlayCircleOutlined, PauseCircleOutlined, XFilled } from '@ant-design/icons';
// import WavesurferPlayer from '@wavesurfer/react';
// import './Waveform.css';

// const Waveform = ({ base64Audio }) => {
//   const [wavesurfer, setWavesurfer] = useState(null);
//   const [isPlaying, setIsPlaying] = useState(false);
//   const [audioUrl, setAudioUrl] = useState('');

//   useEffect(() => {
//     if (base64Audio) {
//       // Convert base64 to a Blob
//       const byteCharacters = atob(base64Audio);
//       const byteNumbers = new Array(byteCharacters.length);
//       for (let i = 0; i < byteCharacters.length; i++) {
//         byteNumbers[i] = byteCharacters.charCodeAt(i);
//       }
//       const byteArray = new Uint8Array(byteNumbers);
//       const blob = new Blob([byteArray], { type: 'audio/mp3' });

//       // Create a URL for the blob
//       const url = URL.createObjectURL(blob);
//       setAudioUrl(url);

//       // Clean up the URL when the component is unmounted or audioUrl changes
//       return () => {
//         URL.revokeObjectURL(url);
//       };
//     }
//   }, [base64Audio]);

//   const onReady = (ws) => {
//     setWavesurfer(ws);
//     setIsPlaying(false);
//   };

//   const onPlayPause = () => {
//     if (wavesurfer) {
//       wavesurfer.playPause();
//       setIsPlaying(!isPlaying);
//     }
//   };

//   const onStop = () => {
//     if (wavesurfer) {
//       wavesurfer.stop();
//       setIsPlaying(false);
//     }
//   };

//   return (
//     <>
//       {audioUrl && (
//         <WavesurferPlayer
//           height={50}
//           waveColor="#146ef5"
//           url={audioUrl}
//           onReady={onReady}
//           onPlay={() => setIsPlaying(true)}
//           onPause={() => setIsPlaying(false)}
//         />
//       )}

//       <div style={{ marginTop: 16, textAlign: 'center' }} >
//         <Button 
//           id={isPlaying ? 'waveform-play-button' : 'waveform-pause-button'}
//           type="primary" 
//           shape="circle" 
//           icon={isPlaying ? <PauseCircleOutlined /> : <PlayCircleOutlined />} 
//           onClick={onPlayPause} 
//           style={{ marginRight: 8 }}
//         />
//         <Button 
//           id={'waveform-stop-button'}
//           type="primary" 
//           shape="circle" 
//           icon={<XFilled />} 
//           onClick={onStop} 
//         />
//       </div>
//     </>
//   );
// };

// export default Waveform;

// import React, { useState } from 'react';
// import { Button } from 'antd';
// import { PlayCircleOutlined, PauseCircleOutlined, XFilled } from '@ant-design/icons';
// import WavesurferPlayer from '@wavesurfer/react';
// import './Waveform.css';

// const Waveform = ({ audioUrl }) => {
//   const [wavesurfer, setWavesurfer] = useState(null);
//   const [isPlaying, setIsPlaying] = useState(false);

//   const onReady = (ws) => {
//     setWavesurfer(ws);
//     setIsPlaying(false);
//   };

//   const onPlayPause = () => {
//     if (wavesurfer) {
//       wavesurfer.playPause();
//       setIsPlaying(!isPlaying);
//     }
//   };

//   const onStop = () => {
//     if (wavesurfer) {
//       wavesurfer.stop();
//       setIsPlaying(false);
//     }
//   };

//   return (
//     <>
//       {audioUrl && (
//         <WavesurferPlayer
//           height={50}
//           waveColor="#146ef5"
//           url={audioUrl}
//           onReady={onReady}
//           onPlay={() => setIsPlaying(true)}
//           onPause={() => setIsPlaying(false)}
//         />
//       )}

//       <div style={{ marginTop: 16, textAlign: 'center' }}>
//         <Button
//           id={isPlaying ? 'waveform-play-button' : 'waveform-pause-button'}
//           type="primary"
//           shape="circle"
//           icon={isPlaying ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
//           onClick={onPlayPause}
//           style={{ marginRight: 8 }}
//         />
//         <Button
//           id="waveform-stop-button"
//           type="primary"
//           shape="circle"
//           icon={<XFilled />}
//           onClick={onStop}
//         />
//       </div>
//     </>
//   );
// };

// export default Waveform;

// src/components/Waveform.js

// src/components/Waveform.js

import React, { useState, useRef, useEffect } from 'react';
import { Button, Slider, Row, Col, Tooltip } from 'antd';
import {
  PlayCircleOutlined,
  PauseCircleOutlined,
  SoundOutlined,
  StopOutlined,
  XFilled,
} from '@ant-design/icons';
import './Waveform.css';

const Waveform = ({ audioUrl }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(100);

  const audioRef = useRef(new Audio());

  useEffect(() => {
    const audio = audioRef.current;

    if (audioUrl) {
      audio.src = audioUrl;
      audio.load();
    }

    const handleLoadedMetadata = () => {
      const audioDuration = audio.duration;
      if (!isNaN(audioDuration) && isFinite(audioDuration)) {
        setDuration(audioDuration);
      } else {
        setDuration(0);
      }
    };

    const handleTimeUpdate = () => {
      const audioCurrentTime = audio.currentTime;
      if (!isNaN(audioCurrentTime) && isFinite(audioCurrentTime)) {
        setCurrentTime(audioCurrentTime);
      }
    };

    const handlePlay = () => {
      setIsPlaying(true);
    };

    const handlePause = () => {
      setIsPlaying(false);
    };

    const handleEnded = () => {
      setIsPlaying(false);
      setCurrentTime(0);
    };

    const handleError = (e) => {
      console.error('Audio playback error:', e);
      // Handle errors if needed
    };

    audio.addEventListener('loadedmetadata', handleLoadedMetadata);
    audio.addEventListener('timeupdate', handleTimeUpdate);
    audio.addEventListener('play', handlePlay);
    audio.addEventListener('pause', handlePause);
    audio.addEventListener('ended', handleEnded);
    audio.addEventListener('error', handleError);

    return () => {
      audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
      audio.removeEventListener('timeupdate', handleTimeUpdate);
      audio.removeEventListener('play', handlePlay);
      audio.removeEventListener('pause', handlePause);
      audio.removeEventListener('ended', handleEnded);
      audio.removeEventListener('error', handleError);
    };
  }, [audioUrl]);

  const togglePlayPause = () => {
    const audio = audioRef.current;
    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
  };

  const handleStop = () => {
    const audio = audioRef.current;
    audio.pause();
    audio.currentTime = 0;
    setIsPlaying(false);
    setCurrentTime(0);
  };

  const handleSliderChange = (value) => {
    const audio = audioRef.current;
    audio.currentTime = value;
    setCurrentTime(value);
  };

  const handleVolumeChange = (value) => {
    const audio = audioRef.current;
    audio.volume = value / 100;
    setVolume(value);
  };

  const formatTime = (time) => {
    if (isNaN(time) || !isFinite(time)) return '';
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    const pad = (num) => String(num).padStart(2, '0');
    return `${pad(minutes)}:${pad(seconds)}`;
  };

  return (
    <>
      {audioUrl && (
        <div
          style={{
            // marginTop: '20px',
            padding: '0 10px 10px 10px',
            // border: '1px solid #1890ff',
            // borderRadius: '10px',
            // backgroundColor: '#e6f7ff',
          }}
        >
          <Row align="middle" style={{ marginTop: '20px' }}>
            <Col span={2}>{formatTime(currentTime)}</Col>
            <Col span={22}>
              <Slider
                min={0}
                max={duration > 0 ? duration : 100}
                onChange={handleSliderChange}
                value={currentTime}
                tooltipVisible={false}
                trackStyle={{ backgroundColor: '#1890ff' }}
                handleStyle={{ borderColor: '#1890ff' }}
                disabled={duration === 0}
              />
            </Col>
            {/* <Col span={3} style={{ textAlign: 'right' }}>
              {formatTime(duration)}
            </Col> */}
          </Row>
          <Row>
            <Col lg={6} md={6} sm={24} xs={24} style={{ textAlign: 'center' }}></Col>
            <Col lg={12} md={12} sm={24} xs={24} style={{ textAlign: 'center' }}>
              <Row justify="center" align="middle" style={{paddingTop: "10px"}}>
                <Tooltip placement="top" title={isPlaying ? "Pause" : "Play"}>
                  <Button
                    id={isPlaying ? 'waveform-play-button' : 'waveform-pause-button'}
                    type="primary"
                    shape="circle"
                    icon={isPlaying ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
                    onClick={togglePlayPause}
                  />
                </Tooltip>
                <Tooltip placement="top" title="Stop">
                  <Button
                    id="waveform-stop-button"
                    type="primary"
                    shape="circle"
                    icon={<XFilled />}
                    onClick={handleStop}
                    style={{ marginLeft: '8px' }}
                  />
                </Tooltip>
              </Row>
            </Col>
            <Col lg={6} md={6} sm={24} xs={24} style={{ textAlign: 'center' }}>
               <Row align="middle" style={{ marginTop: '10px' }}>
                <Col span={2}>
                  <Tooltip placement="top" title="Volume">
                    <SoundOutlined />
                  </Tooltip>
                </Col>
                <Col span={22}>
                  <Slider
                    min={0}
                    max={100}
                    value={volume}
                    onChange={handleVolumeChange}
                    tooltipOpen={false}
                    stylesTrack={{ backgroundColor: 'red' }}
                    stylesHandle={{ borderColor: '#1890ff' }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          
         
        </div>
      )}
    </>
  );
};

export default Waveform;
