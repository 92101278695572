import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, DatePicker, Radio, Button, message, Spin, Row, Col,Typography } from 'antd';
import { useAuth } from '../../../auth/AuthContext';
import IconButtonGroup from '../IconButtonGroup/IconButtonGroup'
import {marked} from 'marked';
import DOMPurify from 'dompurify';

const AssessmentModal = ({open, setOpen, fileId}) => {
    const [fetchAssessmentLoading, setFetchAssessmentLoading] = useState(false)
    const [assessment, setAssessment] = useState(null);
    const auth = useAuth();

    const handleOk = () => {
        setOpen(false);
        setAssessment(null);
    };

    const handleCancel = () => {
        setOpen(false);
        setAssessment(null);
    };

    const markdownToHtml = (markdown) => {
        const rawHtml = marked.parse(markdown); // Adjusted to use `parse` method
        return DOMPurify.sanitize(rawHtml);// Sanitize HTML to prevent XSS attacks
    };

    useEffect(() => {
        if (fileId && open) {
            handleFetchAssessment();
        }
    }, [fileId, open]);

    const handleFetchAssessment = async() => {
      setFetchAssessmentLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/files/audio/${fileId}/get_assessment_report`, {
          headers: {
            'Authorization': `Bearer ${auth.user['access_token']}`,
          },
          method: 'GET',
        });

        if (response.ok) {
          const result = await response.json();   
          setAssessment(result.message)
        } else {
          message.error('No Previous Summary Found to Compare.');
          setOpen(false);
        }
      } catch (error) {
        console.error('Error fetching assessment report', error);
        message.error('Error fetching assessment report');
        setOpen(false);
      }
      setFetchAssessmentLoading(false);
    }
    return (
        <Modal
          title={
            <Row>
              <Col lg={8} md={8} sm={24} xs={24}>                 
                <Typography.Title level={5} style={{ margin: 0 }}>Assessment Report</Typography.Title>
              </Col>
              <Col lg={15} md={15} sm={24} xs={24} style={{ textAlign: "right" }}>
                <IconButtonGroup 
                  text={assessment == null ? "" :assessment} 
                  feature={{ edit: false, copy: false, download: true, email: true }} 
                  fileId={fileId} 
                  contentType={"assessment_report"}
                />
              </Col>
            </Row>
          }
          open={open}
          onOk={handleOk}
          onCancel={handleCancel}
          maskClosable={false}
          centered
          destroyOnClose={false}
          width={1000}
          bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}
        >    
         {!fetchAssessmentLoading ? <div className="message-content" dangerouslySetInnerHTML={{ __html: markdownToHtml(assessment == null ? "" : assessment) }} /> : 
          <div
            style={{
                // height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: "20px"
            }}
            >
            <Spin size="large" />
          </div>}
        </Modal>
    )
}

export default AssessmentModal