import { MessageOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import ChatModal from './ChatModal/ChatModal';

const StickyChat = ({title, fileId}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <>
        <Button type="primary" icon={<MessageOutlined style={{ fontSize: '20px'}}/>} shape="circle" style={{position: "fixed", bottom: 20, right: 70, height: "40px", width: "40px"}} onClick={showModal}></Button>
        <ChatModal isModalOpen={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} title={title} fileId={fileId} />
        </>
    )
}

export default StickyChat