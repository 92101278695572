// src/pages/ForgotPassword.tsx
import React, { useState } from 'react';
import { Layout, Row, Col, Form, Input, Button, Card, Typography, message } from 'antd';
import AppFooter from '../../AppFooter/AppFooter';
import logo from '../../../assets/images/favicon.png';
import { useNavigate, Link } from 'react-router-dom';
import fullLogo from '../../../assets/images/logo-no-background.png';
const { Content } = Layout;
const { Title, Text } = Typography;

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleForgotPassword = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/forgot_password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json',
        },
        body: JSON.stringify({ email: email }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        message.error("Error: " + errorData.detail || 'Error: Failed to send reset link');
        throw new Error(errorData.detail || 'Failed to send reset link');
      }
      localStorage.setItem('userEmail', email);
      message.success('Password reset OTP sent to your email. Please enter the OTP and new password.');
      navigate('/reset-password');
    } catch (error) {
      // message.error(`${error}`);
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout style={{ minHeight: '100vh', background: "rgb(255,255,255)", background: "linear-gradient(207deg, rgba(110,168,255,1) 0%, rgba(255,255,255,1) 20%, rgba(255,255,255,1) 80%, rgba(110,168,255,1) 100%)" }}>
      <Content style={{ padding: '0', textAlign: 'center' }}>
        <Row>
          <Col span={24} style={{textAlign: "left", padding: "20px 30px"}}>
          <img src={fullLogo} alt="" width={'150px'} />
          </Col>
        </Row>
        <Row justify="center" align="middle">
          <Col lg={8} md={8} sm ={0} xs={0}></Col>
          <Col lg={8} md={8} sm ={24} xs={24} style={{ padding: '20px', paddingTop: '10vh' }}>
            <Card style={{ padding: '0px 10px 0px 10px', border: "none", backgroundColor: 'transparent'}}>
              {/* <img src={logo} alt="Eerly Logo" width={'150px'} /> */}
              <Title style={{ marginTop: '-10px', marginBottom: '50px', color: '#146ef5' }} level={2}>
                Welcome to Sporo Health
              </Title>
              <Title level={3}>Forgot Password</Title>
              <Text>Enter your email address to receive an OTP.</Text>
              <Form
                style={{ textAlign: 'left', marginTop: '20px' }}
                layout="vertical"
                onFinish={handleForgotPassword}
              >
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: 'email',
                      message: 'Please enter a valid email address!',
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    style={{ width: '100%' }}
                    htmlType="submit"
                    loading={loading}
                  >
                    Send OTP
                  </Button>
                </Form.Item>
                <Col xs={24} sm={24} lg={24} style={{paddingRight: "5px", paddingLeft: "5px", marginTop: "10px", textAlign: "center"}}>
                    <p className="font-semibold text-muted">
                      Go back to{" "}
                      <Link to="/login" className="text-dark font-bold">
                        Sign In
                      </Link>
                    </p>
                </Col>
              </Form>
            </Card>
          </Col>
          <Col lg={8} md={8} sm ={0} xs={0}></Col>
        </Row>
        <div style={{position: "fixed", bottom: 10, width: "100%"}}>
          <AppFooter />
        </div>
      </Content>
    </Layout>
  );
};

export default ForgotPassword;
