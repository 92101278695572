// src/components/FeedbackModal.jsx

import React, { useState } from 'react';
import {
    Modal,
    Form,
    Input,
    Upload,
    Button,
    Row,
    Col,
    message
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useAuth } from '../../auth/AuthContext';

const { TextArea } = Input;

const FeedbackModal = ({ visible, onCancel, onSuccess }) => {
    const [feedbackForm] = Form.useForm();
    const [uploadFiles, setUploadFiles] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const auth = useAuth();

    // Upload Props Configuration
    const uploadProps = {
        beforeUpload: (file) => {
            const isImage = file.type.startsWith('image/');
            if (!isImage) {
                message.error('You can only upload image files!');
                return Upload.LIST_IGNORE; // Prevent the file from being uploaded
            }
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                message.error('Image must be smaller than 2MB!');
                return Upload.LIST_IGNORE; // Prevent the file from being uploaded
            }
            return false; // Prevent automatic upload
        },
        onChange: ({ fileList }) => {
            setUploadFiles(fileList);
        },
        multiple: true,
        maxCount: 2,
        listType: 'picture',
        showUploadList: true, // Show the list of uploaded files
    };

    // Handle Form Submission
    const handleSubmit = async (values) => {
        setSubmitting(true);
        const formData = new FormData();
        formData.append('feedback', values.feedback);

        if (uploadFiles.length > 0) {
            uploadFiles.forEach((file) => {
                formData.append('images', file.originFileObj);
            });
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/feedback`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${auth.user['access_token']}`,
                },
                body: formData,
            });

            if (response.ok) {
                const result = await response.json();
                message.success('Feedback submitted successfully!');
                feedbackForm.resetFields();
                setUploadFiles([]);
                onCancel();
                if (onSuccess) onSuccess();
            } else {
                const errorData = await response.json();
                message.error(`Failed to submit feedback: ${errorData.detail || 'Unknown error'}`);
            }
        } catch (error) {
            console.error("Error submitting feedback:", error);
            message.error('An error occurred while submitting your feedback.');
        }
        setSubmitting(false);
    };

    return (
        <Modal
            title="Share Your Feedback"
            open={visible}
            onCancel={onCancel}
            footer={null}
            destroyOnClose
            centered
            width={600}
        >
            <Form
                form={feedbackForm}
                layout="vertical"
                onFinish={handleSubmit}
            >
                <Form.Item
                    label="Your Feedback"
                    name="feedback"
                    rules={[
                        { required: true, message: 'Please enter your feedback' },
                        { max: 1000, message: 'Feedback cannot exceed 1000 characters' },
                    ]}
                >
                    <TextArea
                        rows={4}
                        placeholder="Enter your feedback here..."
                        allowClear
                    />
                </Form.Item>

                <Form.Item
                    label="Attach Images (Optional)"
                    name="images"
                >
                    <Upload {...uploadProps}>
                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>
                    <div style={{ marginTop: '8px' }}>
                        <span style={{ color: '#888' }}>
                            You can upload up to 2 images. Each must be smaller than 2MB.
                        </span>
                    </div>
                </Form.Item>

                <Form.Item>
                    <Row justify="end" gutter={16}>
                        <Col>
                            <Button onClick={onCancel}>
                                Cancel
                            </Button>
                        </Col>
                        <Col>
                            <Button type="primary" htmlType="submit" loading={submitting}>
                                Save
                            </Button>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>
        </Modal>
    );
};

// PropTypes for type checking
FeedbackModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func, // Optional callback after successful submission
};

export default FeedbackModal;
