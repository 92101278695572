// src/pages/VerifyEmail.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { Layout, Row, Col, Typography, Card } from 'antd';
import AppFooter from '../../AppFooter/AppFooter';
// import bgImage from '../../assets/images/bg (6).jpg';
import logo from '../../../assets/images/favicon.png';
import fullLogo from '../../../assets/images/logo-no-background.png';

const { Content } = Layout;
const { Title, Text } = Typography;

const VerifyEmail = () => {
  return (
    <Layout style={{ minHeight: '100vh', background: "rgb(255,255,255)", background: "linear-gradient(207deg, rgba(110,168,255,1) 0%, rgba(255,255,255,1) 20%, rgba(255,255,255,1) 80%, rgba(110,168,255,1) 100%)" }}>
      <Content style={{ padding: '0', textAlign: 'center' }}>
        <Row>
          <Col span={24} style={{textAlign: "left", padding: "20px 30px"}}>
          <img src={fullLogo} alt="" width={'150px'} />
          </Col>
        </Row>
        <Row justify="center" align="middle">
          <Col lg={7} md={7} sm ={0} xs={0}></Col>
          <Col lg={10} md={10} sm ={24} xs={24} style={{ padding: '20px', paddingTop: '10vh' }}>
            <Card style={{ padding: '0px 10px 0px 10px', border: "none", backgroundColor: 'transparent'}}>
              {/* <img src={logo} alt="Eerly Logo" width={'150px'} /> */}
              <Title style={{ marginTop: '-10px', marginBottom: '50px', color: '#146ef5' }} level={2}>
                Welcome to Sporo Health
              </Title>

              <Title level={3}>Verify Your Email</Title>
              <Text>
                Thank you for signing up! A verification email has been sent to your email address. Please check your inbox and click on the verification link to activate your account.
              </Text>

              <Text style={{ display: 'block', marginTop: '20px' }}>
                If you did not receive the email, please check your spam folder.
                {/* or <Link to="/resend-verification">click here</Link> to resend the verification email. */}
              </Text>
            </Card>
          </Col>
          <Col lg={7} md={7} sm ={0} xs={0}></Col>
        </Row>
        <div style={{position: "fixed", bottom: 10, width: "100%"}}>
          <AppFooter />
        </div>
      </Content>
    </Layout>
  );
};

export default VerifyEmail;
