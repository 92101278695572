import React, { useState } from 'react';
import { Button, Modal, Typography } from 'antd';
const {Title} = Typography;

const ConfirmationModal = ({open, setOpen, handleEmailSend, loading}) => {


  const handleOk = async() => {
    await handleEmailSend();
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Modal
        open={open}
        title="Confirm Send Email"
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        maskClosable={false}
        destroyOnClose
        footer={[
          <Button type="primary" key="back" onClick={handleCancel} danger>
            No
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
            Yes
          </Button>
        ]}
      >
        <Title level={5} style={{padding: "10px"}}>Are you sure you want to send the email?</Title>
      </Modal>
    </>
  );
};

export default ConfirmationModal;