import React, { useState } from 'react';
import { Modal, Typography, Tabs, Divider, Button } from 'antd';
// import Pdf1 from '../../../assets/pdfs/Business Associate Agreement.pdf';
// import Pdf2 from '../../../assets/pdfs/Privacy Policy.pdf';
import { privacyPolicy, baa } from '../../../Contants';
import './TermsAndConditionsModal.css'

const { Title, Paragraph } = Typography;
const { TabPane } = Tabs;

const TermsAndConditionsModal = ({ isModalVisible, handleOk, handleCancel }) => {
  return (
    <Modal
      title="T&C and Additional Documents"
      open={isModalVisible} // Use 'visible' for Ant Design v4; 'open' for v5
      onOk={handleOk}
      onCancel={handleCancel}
      maskClosable={false}
      destroyOnClose
      centered
      width={1000}
      footer={[
        <Button key="ok" type="primary" onClick={handleOk}>
          OK
        </Button>,
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
      ]}
    >
      <Tabs defaultActiveKey="1" type="card">
        {/* Tab 1: Terms and Conditions */}
        <TabPane tab="Terms and Conditions" key="1">
          <div style={{ height: "70vh", overflowY: "auto", padding: '16px' }}>
            <Typography>
              <Title level={1}>Terms and Conditions</Title>
              <Paragraph><b>Last Updated: 15/09/2024</b></Paragraph>

              <Title level={2}>1. Acceptance of Terms</Title>
              <Paragraph>
                By accessing or using Sporo Health's website or proprietary software platform (“Platform”), you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions and our Privacy Policy. If you do not agree, you may not use our services.
                <br /><br />
                If you are accepting these terms on behalf of a company or other legal entity, you represent that you have the authority to bind that entity to this Agreement. In such cases, “you” and “your” will refer to that entity.
              </Paragraph>

              <Title level={2}>2. Modifications</Title>
              <Paragraph>
                Sporo Health reserves the right to modify, discontinue, or terminate the Website, Platform, or this Agreement at any time without prior notice. If changes are made, we will post the modified terms on the Website. Continued use of our services constitutes acceptance of the modified Agreement. If you do not accept the changes, your only recourse is to stop using the Website or Platform.
              </Paragraph>

              <Title level={2}>3. No Medical Advice</Title>
              <Paragraph>
                Sporo Health does not provide medical care, diagnosis, or treatment. The Platform is intended for facilitating healthcare workflows and transcribing medical visits, but it is not a substitute for professional medical advice. Always seek medical advice from your healthcare provider for any medical concerns. In case of a medical emergency, call 911. Use of our services does not establish a doctor-patient relationship.
              </Paragraph>

              <Title level={2}>4. Use of Personal Information</Title>
              <Paragraph>
                Your use of the Website or Platform may involve providing personal information. This information is governed by our Privacy Policy, which details how we collect, store, and use your data.
              </Paragraph>

              <Title level={2}>5. User Information and Conduct</Title>
              <Paragraph>
                You agree to provide accurate and current information when registering for an account on Sporo Health and are responsible for maintaining the confidentiality of your login credentials. You agree to use Sporo Health in compliance with all applicable laws and refrain from:
                <ul>
                  <li>Using the Platform for any unlawful purposes.</li>
                  <li>Collecting information for competitive purposes.</li>
                  <li>Impersonating any person or entity.</li>
                  <li>Interfering with the operation of the Platform, including hacking or introducing harmful code.</li>
                </ul>
                Sporo Health reserves the right to suspend or terminate your access to the Platform if you violate these guidelines.
              </Paragraph>

              <Title level={2}>6. Intellectual Property</Title>
              <Paragraph>
                All content, including but not limited to software, text, graphics, and trademarks, is the property of Sporo Health or its licensors. Unauthorized use, reproduction, or distribution of the content is prohibited unless expressly permitted by Sporo Health.
                <br /><br />
                You may not decompile, reverse-engineer, or otherwise tamper with the software or Platform.
              </Paragraph>

              <Title level={2}>7. Feedback</Title>
              <Paragraph>
                We welcome feedback, comments, and suggestions to improve Sporo Health. Any feedback you provide may be used by us without restriction or compensation to you. Please do not submit confidential or proprietary information in your feedback.
              </Paragraph>

              <Title level={2}>8. No Warranties; Limitation of Liability</Title>
              <Paragraph>
                Sporo Health provides the Platform on an "as is" and "as available" basis. We disclaim all warranties, whether express or implied, including warranties of merchantability, fitness for a particular purpose, and non-infringement. We do not guarantee uninterrupted, error-free, or secure access to the Platform.
                <br /><br />
                To the fullest extent permitted by law, Sporo Health is not liable for any indirect, incidental, or consequential damages arising from your use of the Platform, including but not limited to loss of data, profits, or goodwill.
              </Paragraph>

              <Title level={2}>9. External Links</Title>
              <Paragraph>
                The Website may contain links to external websites. Sporo Health is not responsible for the content or privacy practices of third-party websites. Accessing linked sites is at your own risk.
              </Paragraph>

              <Title level={2}>10. Indemnification</Title>
              <Paragraph>
                You agree to indemnify and hold Sporo Health harmless from any claims, losses, liabilities, damages, costs, or expenses (including attorney fees) arising from your use of the Platform, your violation of these Terms, or your infringement of any rights of a third party.
              </Paragraph>

              <Title level={2}>11. Termination</Title>
              <Paragraph>
                Sporo Health may terminate or suspend your access to the Platform at any time for violations of these Terms or if we determine your actions are harmful to other users, Sporo Health, or third parties. Upon termination, your right to use the Platform ceases immediately.
              </Paragraph>

              <Title level={2}>12. Binding Arbitration and Class Action Waiver</Title>
              <Paragraph>
                Any disputes arising from this Agreement will be resolved through binding arbitration, governed by the Federal Arbitration Act (FAA). By agreeing to arbitration, you waive your right to a jury trial or to participate in a class action. Arbitration will be conducted by a neutral arbitrator under JAMS rules, and the decision will be final.
                <br /><br />
                You agree that any claims against Sporo Health will be in your individual capacity and not as a part of any class action.
              </Paragraph>

              <Title level={2}>13. Governing Law and Jurisdiction</Title>
              <Paragraph>
                This Agreement is governed by the laws of the jurisdiction in which Sporo Health is headquartered, without regard to conflict of law principles. Any disputes not subject to arbitration will be resolved in the courts located in that jurisdiction.
              </Paragraph>

              <Title level={2}>14. Changes to Terms</Title>
              <Paragraph>
                Sporo Health reserves the right to modify these Terms and Conditions at any time. Any changes will be posted on the Website. Continued use of the Platform after changes are posted constitutes your acceptance of the modified terms.
              </Paragraph>

              <Title level={2}>15. Miscellaneous</Title>
              <Paragraph>
                If any provision of this Agreement is found to be unenforceable, the remaining provisions will continue in full force and effect. This Agreement constitutes the entire understanding between you and Sporo Health regarding the use of the Website and Platform.
              </Paragraph>

              <Title level={2}>16. Contact Us</Title>
              <Paragraph>
                If you have any questions about these Terms and Conditions, please contact us at:
                <br />
                Email: <a href="mailto:contact@sporohealth.com" target="_blank" rel="noopener noreferrer">contact@sporohealth.com</a>
              </Paragraph>
              <Divider />
              <Paragraph>
                By using Sporo Health’s services, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.
              </Paragraph>
            </Typography>
          </div>
        </TabPane>

        {/* Tab 2: PDF Document 1 */}
        <TabPane tab="Business Associate Agreement" key="2">
          <div style={{ height: '70vh', overflowY: 'hidden', padding: '16px' }}>
            <iframe
              src={`data:application/pdf;base64, ${baa}`}
              title="Business Associate Agreement"
              width="100%"
              height="100%"
              style={{ border: 'none' }}
            ></iframe>
          </div>
        </TabPane>

        {/* Tab 3: PDF Document 2 */}
        <TabPane tab="Privacy Policy" key="3">
          <div style={{ height: '70vh', overflowY: 'hidden', padding: '16px' }}>
            <iframe
              src={`data:application/pdf;base64, ${privacyPolicy}`}
              title="Privacy Policy"
              width="100%"
              height="100%"
              style={{ border: 'none' }}
            ></iframe>
          </div>
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export default TermsAndConditionsModal;
