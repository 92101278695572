// src/components/BulkUploadModal/BulkUploadModal.jsx

import React, { useState } from 'react';
import { Modal, Form, Upload, Button, Space, message, Typography } from 'antd';
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const { Text } = Typography;

const BulkUploadModal = ({
  visible,
  onCancel,
  onUploadSuccess,
  apiUrl,
  authToken,
  templateDownloadUrl,
}) => {
  const [uploading, setUploading] = useState(false);

  const [form] = Form.useForm();

  // Handle form submission
  const handleFinish = async (values) => {
    const { file } = values;
    if (!file || !file.file) {
      message.error('Please select a file to upload.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file.file);

    setUploading(true);
    try {
      const response = await fetch(`${apiUrl}/patients/bulk_create`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${authToken}`,
        },
        body: formData,
      });

      if (response.ok) {
        const result = await response.json();
        message.success('Bulk upload of patients is successful!');
        form.resetFields();
        onUploadSuccess(result);
        onCancel();
      } else {
        const errorData = await response.json();
        message.error(errorData.detail || 'Bulk upload of patients is failed.');
      }
    } catch (error) {
      console.error('Error during bulk upload of patients:', error);
      message.error('An error occurred during bulk upload of patients.');
    } finally {
      setUploading(false);
    }
  };

  // Handle template download
  const handleDownloadTemplate = async () => {
    try {
      const response = await fetch(`${templateDownloadUrl}`, {
        headers: {
          'Authorization': `Bearer ${authToken}`,
        },
      });

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        // Extract filename from response headers or default name
        const disposition = response.headers.get('Content-Disposition');
        let filename = 'bulk_upload_template.xlsx';
        if (disposition && disposition.includes('filename=')) {
          filename = disposition.split('filename=')[1].replace(/"/g, '');
        }
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } else {
        const errorData = await response.json();
        message.error(errorData.detail || 'Failed to download template.');
      }
    } catch (error) {
      console.error('Error downloading template:', error);
      message.error('An error occurred while downloading the template.');
    }
  };

  return (
    <Modal
      open={visible}
      title="Bulk Upload Patients"
      centered
      onCancel={onCancel}
      footer={null}
      destroyOnClose
      width={600}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFinish}
      >
        {/* Download Template Button */}
        <Form.Item>
          <Button
            icon={<DownloadOutlined />}
            onClick={handleDownloadTemplate}
            block
          >
            Download Sample Template
          </Button>
        </Form.Item>

        {/* Upload File Button */}
        <Form.Item
          name="file"
          label="Select File to Upload"
          valuePropName="file"
          getValueFromEvent={(e) => e}
          rules={[
            { required: true, message: 'Please upload a file.' },
            {
              validator: (_, value) => {
                if (!value || !value.file) {
                  return Promise.resolve();
                }
                const isAllowedType =
                  value.file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                  value.file.type === 'text/csv';
                if (!isAllowedType) {
                  return Promise.reject(new Error('Only .xlsx and .csv files are allowed.'));
                }
                return Promise.resolve();
              },
            },
          ]}
          style={{marginBottom: "5px"}}
        >
          <Upload
            beforeUpload={() => false} // Prevent automatic upload
            accept=".xlsx,.csv"
            maxCount={1}
          >
            <Button icon={<UploadOutlined />}>Select File</Button>
          </Upload>
        </Form.Item>
        <Text type="danger" >Please upload only .xlsx or .csv files.</Text>   

        {/* Submit Upload Button */}
        <Form.Item style={{marginTop: "20px"}}>
          <Button type="primary" htmlType="submit" loading={uploading} block>
            Upload
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

// Define PropTypes for type checking
BulkUploadModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUploadSuccess: PropTypes.func.isRequired,
  apiUrl: PropTypes.string.isRequired,
  authToken: PropTypes.string.isRequired,
  templateDownloadUrl: PropTypes.string.isRequired,
};

export default BulkUploadModal;
