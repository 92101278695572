// PatientNoteModal.jsx
import React, { useState, useEffect, useContext } from 'react';
import { Modal, Form, Input, DatePicker, Radio, Button, message, Spin } from 'antd';
import {
  EditOutlined,
  CopyOutlined,
  DownloadOutlined,
  InfoCircleOutlined,
  MailOutlined,
  LoadingOutlined,
  SaveOutlined
} from '@ant-design/icons';
import ThemeContext from '../../../context/ThemeContext';
import { useAuth } from '../../../auth/AuthContext';

const PatientNoteModal = ({ open, setOpen, fileId, handleSendEmail, sendEmailLoading }) => {  
  const { themeMode } = useContext(ThemeContext);
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editedText, setEditedText] = useState("");
  const [editLoading, setEditLoading] = useState(false);

  useEffect(() => {
    if (open && fileId) fetchPatientNote();
  }, [open, fileId]);

  const fetchPatientNote = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/files/audio/${fileId}/get_patient_note`, {
        headers: {
          'Authorization': `Bearer ${auth.user['access_token']}`,
        },
        method: 'GET'
      });

      if (response.ok) {
        const result = await response.json();
        setEditedText(result.message);
      } else {
        message.error('Failed to fetch patient note');
      }
    } catch (error) {
      console.error('Error fetching patient note', error);
      message.error('Error fetching patient note');
    }
    setLoading(false);
  }

  const handleEdit = async () => {
    setEditLoading(true);
    try {
      const formData = new FormData();
      formData.append('edited_patient_note', editedText);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/files/audio/${fileId}/edit_patient_note`, {
        headers: {
          'Authorization': `Bearer ${auth.user['access_token']}`,
        },
        method: 'PATCH',
        body: formData,
      });

      if (response.ok) {
        const result = await response.json();
        message.success(result.message);
      } else {
        message.error('Failed to edit patient note');
      }
    } catch (error) {
      console.error('Error editing patient note', error);
      message.error('Error editing patient note');
    }
    setEditLoading(false);
    setEdit(false);
  }

  // Function to show confirmation modal before sending email
  const showSendEmailConfirm = () => {
    Modal.confirm({
      title: 'Confirm Send Email',
      content: 'Are you sure you want to send the email?',
      okText: 'Yes',
      cancelText: 'No',
      className: themeMode === 'dark' ? 'dark-modal' : 'light-modal',
      centered: true,
      onOk: () => {
        handleSendEmail();
      },
      onCancel: () => {
        // Optional: You can add any logic here if needed when the user cancels
      },
    });
  };

  const copyToClipboard = async () => {
    try {
        await navigator.clipboard.writeText(editedText);
        message.success('Copied!');
    } catch (err) {
        message.error('Failed to copy!');
        console.error('Failed to copy: ', err);
    }
  };

  return (
    <Modal
      title={edit ? "Edit Patient Note" : "View Patient Note"}
      open={open}
      onCancel={() => { setOpen(false); setEdit(false); }}
      centered
      width={800}
      maskClosable={false}
      keyboard={false}
      footer={[
        <Button
          key="cancel"
          onClick={() => { setOpen(false); setEdit(false); }}
          disabled={loading}
        >
          Cancel
        </Button>,
        <Button
          key="copyToClipboard"
          onClick={copyToClipboard}
          success
          disabled={loading}
          icon={<CopyOutlined />}
          style={{borderColor: '#64B441', color: '#64B441'}}
        >
          Copy to Clipboard
        </Button>,
        <Button
          key="sendEmail"
          onClick={showSendEmailConfirm}
          danger
          disabled={loading}
          loading={sendEmailLoading}
          icon={<MailOutlined />}
        >
          Send Email
        </Button>,        
        <Button
          key="editOrSave"
          type="primary"
          loading={editLoading}
          onClick={() => { edit ? handleEdit() : setEdit(true) }}
          disabled={loading}
          icon={edit? <SaveOutlined /> : <EditOutlined />}
        >
          {edit ? "Save" : "Edit"}
        </Button>
      ]}
    >
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: "20px"
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <>
          <Input.TextArea
            rows={15}
            value={editedText}
            onChange={(e) => setEditedText(e.target.value)}
            readOnly={!edit}
          />
          <small style={{ color: "red", fontWeight: 600, fontStyle: "italic" }}>
            <InfoCircleOutlined style={{ marginRight: "10px" }} />
            Please maintain the same structure while editing.
          </small>
        </>
      )}
    </Modal>
  )
}

export default PatientNoteModal;