import React, { useState, useEffect } from 'react';
import { Row, Col, Button, message, Divider, Typography, Switch } from 'antd';
import { useAuth } from '../../../auth/AuthContext';
const { Title } = Typography;

const EnableTwoFactorAuthentication = () => {
    const auth = useAuth();
    const [loading, setLoading] = useState(false);
    const [enable2FA, setEnable2FA] = useState(false);

    useEffect(() => {
        fetchAuthenticationData();
    }, [auth]);

    const fetchAuthenticationData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/get_2fa_status`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${auth.user['access_token']}`,
                    'Content-Type': 'application/json',
                }
            });

            if (response.ok) {
                const result = await response.json();
                setEnable2FA(result.email_2fa_enabled);
            } else {
                message.error('Failed to fetch two-factor authentication status');
            }
        } catch (error) {
            console.error('Error fetching two-factor authentication:', error);
            message.error('Error fetching two-factor authentication');
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const formData =  new FormData();
            formData.append("enable_2fa", enable2FA)
            const response = await fetch(`${process.env.REACT_APP_API_URL}/update_2fa`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${auth.user['access_token']}`
                },
                body: formData,
            });

            if (response.ok) {
                const result = await response.json();
                message.success(result.message);
            } else {
                message.error('Failed to update two-factor authentication');
            }
        } catch (error) {
            console.error('Error updating two-factor authentication:', error);
            message.error('Error updating two-factor authentication');
        } finally {
            setLoading(false);
        }
    };

    const handleSwitchChange = (checked) => {
        setEnable2FA(checked);
    };

    return (
        <Row>
            <Col lg={10} md={12} sm={24} xs={24}>
                <div>
                    <Title level={2} style={{ marginTop: "0px" }}>Turn on Two-Factor Authentication</Title>
                    <Divider />
                    <Row>
                        <Col span={12}>
                            <Switch
                                checked={enable2FA}
                                onChange={handleSwitchChange}
                                loading={loading}
                            />
                        </Col>
                        <Col span={12}>
                            <Button
                                type="primary"
                                onClick={handleSubmit}
                                loading={loading}
                                style={{}}
                            >
                            Save
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
    );
};

export default EnableTwoFactorAuthentication;
