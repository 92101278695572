// src/components/LinkedInLogin/LinkedInLogin.tsx
import React from 'react';
import { Button } from 'antd';
import googleLogo from '../../../assets/images/google_logo.png'

const GoogleLogin = () => {
  const handleLogin = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/login/google`;
  };

  return (
    <Button type="default" style={{width: "100%"}} onClick={handleLogin} size={'large'} ><img src={googleLogo} style={{width: "20px", height: "auto"}} alt="Microsoft Logo" /></Button>
  );
};

export default GoogleLogin;
