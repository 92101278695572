// src/pages/Signup.tsx
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Layout, Row, Col, Form, Typography, Input, Button, Card, message, Checkbox, Modal, Divider } from 'antd';
import AppFooter from '../AppFooter/AppFooter';
import logo from '../../assets/images/favicon.png';
import fullLogo from '../../assets/images/logo-no-background.png';
import { useAuth } from '../../auth/AuthContext';
import PhoneInput from 'react-phone-input-2'
import TermsAndConditionsModal from './TermsAndConditionsModal/TermsAndConditionsModal'
import 'react-phone-input-2/lib/style.css'
import './Signup.css'

const { Content } = Layout;
const { Title, Paragraph } = Typography;

const Signup = () => {
  // const businessEmailDomains = [
  //   "gmail.com", "yahoo.com", "outlook.com", "hotmail.com", "aol.com",
  //   "zoho.com", "protonmail.com", "icloud.com", "gmx.com", "yandex.com",
  //   "mail.com", "tutanota.com", "lycos.com", "fastmail.com", "hushmail.com",
  //   "mailfence.com", "inbox.com", "rediffmail.com", "runbox.com",
  //   "mailbox.org", "posteo.net", "openmailbox.org"
  // ];

  const [formData, setFormData] = useState({
  "first_name": "",
  "last_name": "",
  "contact_number": "",
  "contact_address": "",
  "organisation_name": "",
  "email": "",
  "username": "",
  "password": "",
  "confirm_password": ""
  });
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setIsChecked(true); // Set checkbox as checked when modal OK is clicked
    setIsDisabled(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsChecked(false); // Uncheck checkbox when modal is canceled
    setIsDisabled(false);
  };

  const handleCheckboxChange = (e) => {
    // Prevent default checkbox behavior
    e.preventDefault();
    showModal();
  };
  
  const auth = useAuth();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await auth.register(formData);      
      navigate('/verify-email');
    } catch (error) {
      message.error('Registration failed');
    } finally {
      setLoading(false);
    }
  };
  
  const validateBusinessEmail = (_, value) => {
    if (!value) {
      return Promise.reject(new Error('Please input your company email!'));
    }
    // const domain = value.split('@')[1];
    // if (businessEmailDomains.includes(domain)) {
    //   return Promise.reject(new Error('Business email required, free email addresses are not allowed'));
    // }
    return Promise.resolve();
  };

  const validatePassword = (_, value) => {
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!-/:-@[-`{-~])[A-Za-z\d!-/:-@[-`{-~]{8,}$/;
    if (!passwordPattern.test(value)) {
      return Promise.reject(new Error('Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character'));
    }
    return Promise.resolve();
  };

  return (
    <Layout style={{ minHeight: '100vh', background: "rgb(255,255,255)", background: "linear-gradient(207deg, rgba(110,168,255,1) 0%, rgba(255,255,255,1) 20%, rgba(255,255,255,1) 80%, rgba(110,168,255,1) 100%)" }}>
      <Content style={{ padding: '0', textAlign: "center" }}>
        <Row>
          <Col span={24} style={{textAlign: "left", padding: "20px 30px"}}>
          <img src={fullLogo} alt="" width={'150px'} />
          </Col>
        </Row>
        <Row justify="center" align="middle">
          <Col lg={7} md={7} sm ={0} xs={0}></Col>
          <Col lg={10} md={10} sm ={24} xs={24} style={{ padding: '0px 20px 0px 20px'}}>
            <Card style={{ padding: '0px 10px 0px 10px', border: "none", backgroundColor: 'transparent'}}>
              {/* <img src={logo} alt="" width={"150px"} style={{ marginBottom: "20px" }} /> */}
              <Title style={{ marginTop: '-10px', marginBottom: '50px', color: '#146ef5' }} level={2}>Create your Sporo Scribe AI Account</Title>
              <Form layout="vertical" form={form} onFinish={handleSubmit}>
                <Row>
                  <Col xs={24} sm={24} lg={12} style={{paddingRight: "5px", paddingLeft: "5px"}}>
                    <Form.Item label="First Name" name="first_name" rules={[{ required: true, message: 'Please enter your fist name!' }]}>
                      <Input name="first_name" value={formData.first_name} onChange={handleChange} />
                    </Form.Item>
                  </Col>
                   <Col xs={24} sm={24} lg={12} style={{paddingRight: "5px", paddingLeft: "5px"}}>
                    <Form.Item label="Last Name" name="last_name" rules={[{ required: true, message: 'Please enter your last name!' }]}>
                      <Input name="last_name" value={formData.last_name} onChange={handleChange} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} lg={12} style={{paddingRight: "5px", paddingLeft: "5px"}}>
                    <Form.Item label="Organisation Name" name="organisation_name" rules={[{ required: true, message: 'Please enter your organisation name!' }]}>
                      <Input name="organisation_name" value={formData.organisation_name} onChange={handleChange} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} lg={12} style={{paddingRight: "5px", paddingLeft: "5px"}}>
                    <Form.Item label="Contact Number" name="contact_number" rules={[{ required: true, message: 'Please enter your contact number!' }]}>
                      <PhoneInput
                        style={{width: '100%'}}
                        country={'us'}
                        placeholder="Enter phone number"
                        value={formData.contact_number} 
                        onChange={(v)=>setFormData(prevState => ({
                          ...prevState,
                          contact_number: v,
                        }))}/>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} lg={24} style={{paddingRight: "5px", paddingLeft: "5px"}}>
                    <Form.Item
                      label="Contact Address"
                      name="contact_address"
                      rules={[{ required: true, message: 'Please enter your email!' }]}
                    >
                      <Input.TextArea name="contact_address" value={formData.contact_address} onChange={handleChange} autoSize={{ minRows: 3, maxRows: 5 }} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} lg={12} style={{paddingRight: "5px", paddingLeft: "5px"}}>
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[{ validator: validateBusinessEmail }, { required: true, message: 'Please enter your email!' }]}
                    >
                      <Input name="email" value={formData.email} onChange={handleChange} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} lg={12} style={{paddingRight: "5px", paddingLeft: "5px"}}>
                    <Form.Item label="Username" name="username" rules={[{ required: true, message: 'Please enter your username!' }]}>
                      <Input name="username" value={formData.username} onChange={handleChange} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} lg={12} style={{paddingRight: "5px", paddingLeft: "5px"}}>
                    <Form.Item
                      label="Password"
                      name="password"
                      rules={[{ validator: validatePassword }, { required: true, message: 'Please enter your password!' }]}
                    >
                      <Input.Password name="password" value={formData.password} onChange={handleChange} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} lg={12} style={{paddingRight: "5px", paddingLeft: "5px"}}>
                    <Form.Item label="Confirm Password" name="confirm_password" dependencies={['password']} rules={[
                      { required: true, message: 'Please confirm your password!' },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('The two passwords that you entered do not match!'));
                        },
                      }),
                    ]}>
                      <Input.Password name="confirm_password" value={formData.confirm_password} onChange={handleChange} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} lg={24} style={{paddingRight: "5px", paddingLeft: "5px", marginTop: "20px", textAlign: "left"}}>
                    <Checkbox onClick={handleCheckboxChange} checked={isChecked}>I accept the <span onClick={showModal} style={{ color: 'blue', cursor: 'pointer' }}>T&C, BAA, and Privacy Policy</span>.</Checkbox>
                  </Col>
                  <Col xs={24} sm={24} lg={24} style={{paddingRight: "5px", paddingLeft: "5px", marginTop: "20px"}}>
                    <Form.Item>
                      <Button type="primary" htmlType="submit" style={{ width: "100%" }} loading={loading} disabled={!isDisabled}>
                        SIGN UP
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} lg={24} style={{paddingRight: "5px", paddingLeft: "5px", marginTop: "10px"}}>
                    <p className="font-semibold text-muted">
                      Already have an account?{" "}
                      <Link to="/login" className="text-dark font-bold">
                        Sign In
                      </Link>
                    </p>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
          <Col lg={7} md={7} sm ={0} xs={0}></Col>
        </Row>
        <TermsAndConditionsModal isModalVisible={isModalVisible} handleOk={handleOk} handleCancel={handleCancel}/>
        {/* <Modal title="Terms and Conditions" open={isModalVisible} onOk={handleOk} onCancel={handleCancel} maskClosable={false} destroyOnClose centered width={1000}>
          <div style={{height: "80vh", overflowY: "auto"}}>
            <Typography>
              <Title level={1}>Terms and Conditions</Title>
              <Paragraph><b>Last Updated: 15/09/2024</b></Paragraph>

              <Title level={2}>1. Acceptance of Terms</Title>
              <Paragraph>
                By accessing or using Sporo Health's website or proprietary software platform (“Platform”), you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions and our Privacy Policy. If you do not agree, you may not use our services.
                <br /><br />
                If you are accepting these terms on behalf of a company or other legal entity, you represent that you have the authority to bind that entity to this Agreement. In such cases, “you” and “your” will refer to that entity.
              </Paragraph>

              <Title level={2}>2. Modifications</Title>
              <Paragraph>
                Sporo Health reserves the right to modify, discontinue, or terminate the Website, Platform, or this Agreement at any time without prior notice. If changes are made, we will post the modified terms on the Website. Continued use of our services constitutes acceptance of the modified Agreement. If you do not accept the changes, your only recourse is to stop using the Website or Platform.
              </Paragraph>

              <Title level={2}>3. No Medical Advice</Title>
              <Paragraph>
                Sporo Health does not provide medical care, diagnosis, or treatment. The Platform is intended for facilitating healthcare workflows and transcribing medical visits, but it is not a substitute for professional medical advice. Always seek medical advice from your healthcare provider for any medical concerns. In case of a medical emergency, call 911. Use of our services does not establish a doctor-patient relationship.
              </Paragraph>

              <Title level={2}>4. Use of Personal Information</Title>
              <Paragraph>
                Your use of the Website or Platform may involve providing personal information. This information is governed by our Privacy Policy, which details how we collect, store, and use your data.
              </Paragraph>

              <Title level={2}>5. User Information and Conduct</Title>
              <Paragraph>
                You agree to provide accurate and current information when registering for an account on Sporo Health and are responsible for maintaining the confidentiality of your login credentials. You agree to use Sporo Health in compliance with all applicable laws and refrain from:
                <ul>
                  <li>Using the Platform for any unlawful purposes.</li>
                  <li>Collecting information for competitive purposes.</li>
                  <li>Impersonating any person or entity.</li>
                  <li>Interfering with the operation of the Platform, including hacking or introducing harmful code.</li>
                </ul>
                Sporo Health reserves the right to suspend or terminate your access to the Platform if you violate these guidelines.
              </Paragraph>

              <Title level={2}>6. Intellectual Property</Title>
              <Paragraph>
                All content, including but not limited to software, text, graphics, and trademarks, is the property of Sporo Health or its licensors. Unauthorized use, reproduction, or distribution of the content is prohibited unless expressly permitted by Sporo Health.
                <br /><br />
                You may not decompile, reverse-engineer, or otherwise tamper with the software or Platform.
              </Paragraph>

              <Title level={2}>7. Feedback</Title>
              <Paragraph>
                We welcome feedback, comments, and suggestions to improve Sporo Health. Any feedback you provide may be used by us without restriction or compensation to you. Please do not submit confidential or proprietary information in your feedback.
              </Paragraph>

              <Title level={2}>8. No Warranties; Limitation of Liability</Title>
              <Paragraph>
                Sporo Health provides the Platform on an "as is" and "as available" basis. We disclaim all warranties, whether express or implied, including warranties of merchantability, fitness for a particular purpose, and non-infringement. We do not guarantee uninterrupted, error-free, or secure access to the Platform.
                <br /><br />
                To the fullest extent permitted by law, Sporo Health is not liable for any indirect, incidental, or consequential damages arising from your use of the Platform, including but not limited to loss of data, profits, or goodwill.
              </Paragraph>

              <Title level={2}>9. External Links</Title>
              <Paragraph>
                The Website may contain links to external websites. Sporo Health is not responsible for the content or privacy practices of third-party websites. Accessing linked sites is at your own risk.
              </Paragraph>

              <Title level={2}>10. Indemnification</Title>
              <Paragraph>
                You agree to indemnify and hold Sporo Health harmless from any claims, losses, liabilities, damages, costs, or expenses (including attorney fees) arising from your use of the Platform, your violation of these Terms, or your infringement of any rights of a third party.
              </Paragraph>

              <Title level={2}>11. Termination</Title>
              <Paragraph>
                Sporo Health may terminate or suspend your access to the Platform at any time for violations of these Terms or if we determine your actions are harmful to other users, Sporo Health, or third parties. Upon termination, your right to use the Platform ceases immediately.
              </Paragraph>

              <Title level={2}>12. Binding Arbitration and Class Action Waiver</Title>
              <Paragraph>
                Any disputes arising from this Agreement will be resolved through binding arbitration, governed by the Federal Arbitration Act (FAA). By agreeing to arbitration, you waive your right to a jury trial or to participate in a class action. Arbitration will be conducted by a neutral arbitrator under JAMS rules, and the decision will be final.
                <br /><br />
                You agree that any claims against Sporo Health will be in your individual capacity and not as a part of any class action.
              </Paragraph>

              <Title level={2}>13. Governing Law and Jurisdiction</Title>
              <Paragraph>
                This Agreement is governed by the laws of the jurisdiction in which Sporo Health is headquartered, without regard to conflict of law principles. Any disputes not subject to arbitration will be resolved in the courts located in that jurisdiction.
              </Paragraph>

              <Title level={2}>14. Changes to Terms</Title>
              <Paragraph>
                Sporo Health reserves the right to modify these Terms and Conditions at any time. Any changes will be posted on the Website. Continued use of the Platform after changes are posted constitutes your acceptance of the modified terms.
              </Paragraph>

              <Title level={2}>15. Miscellaneous</Title>
              <Paragraph>
                If any provision of this Agreement is found to be unenforceable, the remaining provisions will continue in full force and effect. This Agreement constitutes the entire understanding between you and Sporo Health regarding the use of the Website and Platform.
              </Paragraph>

              <Title level={2}>16. Contact Us</Title>
              <Paragraph>
                If you have any questions about these Terms and Conditions, please contact us at:
                <br />
                Email: <a href="mailto:contact@sporohealth.com" target="_blank" rel="noopener noreferrer">contact@sporohealth.com</a>
              </Paragraph>
              <Divider></Divider>
              <Paragraph>
                By using Sporo Health’s services, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.
              </Paragraph>
            </Typography>

          </div>
        </Modal> */}
      </Content>
      <AppFooter />
    </Layout>
  );
};

export default Signup;
