import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  Layout,
  Row,
  Col,
  Form,
  Input,
  Button,
  message,
  Typography,
  Divider,
  Modal,
} from 'antd';
import { useAuth } from '../../../auth/AuthContext';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './Profile.css';
import {
  EditOutlined,
  SaveOutlined,
  LockOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import ThemeContext from '../../../context/ThemeContext';
import '../../../assets/css/ModalStyle.css'

const { Content } = Layout;
const { Title, Text } = Typography;
const { confirm } = Modal;

const Profile = () => {
  const { themeMode } = useContext(ThemeContext);
  const [form] = Form.useForm();
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    contact_number: '',
    contact_address: '',
    organisation_name: '',
    email: '',
    username: '',
  });
  const [emailEditMode, setEmailEditMode] = useState(false);
  const [emailChangeLoading, setEmailChangeLoading] = useState(false);
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [otpLoading, setOtpLoading] = useState(false);
  const [timer, setTimer] = useState(60); // 60 seconds for the countdown timer
  const [isTimerActive, setIsTimerActive] = useState(false); // Disable Regenerate OTP while timer is active
  const [otpRequested, setOtpRequested] = useState(false); // New state to track OTP request
  const otpRefs = useRef([]);

  useEffect(() => {
    const userDetails = localStorage.getItem('userDetails');
    const user = userDetails && JSON.parse(userDetails);

    const userData = {
      ...user,
      username: user.sub,
    };
    setFormData(userData);
    form.setFieldsValue(userData);
  }, [auth, form]);

  // Timer for OTP regeneration
  useEffect(() => {
    let interval = null;
    if (isTimerActive && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsTimerActive(false); // Enable regenerate button when timer is 0
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timer, isTimerActive]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const { email, username, ...updateData } = formData; // Don't send email and username in update
      const response = await fetch(`${process.env.REACT_APP_API_URL}/update_user`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${auth.user['access_token']}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updateData),
      });

      if (response.ok) {
        message.success('Profile updated successfully');
        const data = {
          ...formData,
          sub: formData.username,
        };
        localStorage.setItem('userDetails', JSON.stringify(data));
        setEditMode(false);
      } else {
        const errorData = await response.json();
        message.error(errorData.detail || 'Failed to update profile');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      message.error('Error updating profile');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    form.setFieldsValue(formData); // Reset form to original values
    setEmailEditMode(false); // Also reset email edit mode
    setOtp(new Array(6).fill(''));
    setOtpRequested(false); // Reset OTP request state
  };

  // Email Change Handlers
  const handleRequestEmailChange = () => {
    const newEmail = form.getFieldValue('new_email');
    if (!newEmail) {
      message.error('Please enter a new email address.');
      return;
    }

    confirm({
      title: 'Confirm Email Change',
      content: `Are you sure you want to change your email to ${newEmail}? An OTP will be sent to this email.`,
      okText: 'Yes',
      cancelText: 'No',
      centered: true,
      className: themeMode === 'dark' ? 'dark-modal' : 'light-modal',
      onOk: async () => {
        setEmailChangeLoading(true);
        setIsTimerActive(true);
        setTimer(60);
        try {
          const newFormData = new FormData();
          newFormData.append("new_email", newEmail)
          const response = await fetch(`${process.env.REACT_APP_API_URL}/change_email`, {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${auth.user['access_token']}`,
              // 'Content-Type': 'application/json',
            },
            body: newFormData// JSON.stringify(newFormData),
          });

          if (response.ok) {
            const data = await response.json();
            message.success(data.message || 'OTP sent to the new email.');
            setOtpRequested(true); // Set OTP requested to true to show OTP fields
          } else {
            const errorData = await response.json();
            message.error(errorData.detail || 'Failed to request email change.');
            setIsTimerActive(false); // Stop timer if request failed
            setTimer(0);
          }
        } catch (error) {
          console.error('Error requesting email change:', error);
          message.error('Error requesting email change.');
          setIsTimerActive(false); // Stop timer if request failed
          setTimer(0);
        } finally {
          setEmailChangeLoading(false);
        }
      },
    });
  };

  const handleConfirmEmailChange = async () => {
    const enteredOtp = otp.join('');
    if (enteredOtp.length !== 6) {
      message.error('Please enter a valid 6-digit OTP.');
      return;
    }

    confirm({
      title: 'Confirm Email Change',
      content: 'Are you sure you want to confirm the email change with the provided OTP?',
      okText: 'Yes',
      cancelText: 'No',
      centered: true,
      className: themeMode === 'dark' ? 'dark-modal' : 'light-modal',
      onOk: async () => {
        setOtpLoading(true);
        try {
          const newFormData = new FormData();
          newFormData.append("otp", parseInt(enteredOtp, 10))
          const response = await fetch(`${process.env.REACT_APP_API_URL}/change_email/otp`, {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${auth.user['access_token']}`,
              // 'Content-Type': 'application/json',
            },
            body: newFormData
          });

          if (response.ok) {
            const data = await response.json();
            message.success(data.message || 'Email updated successfully.');
            // Update local state and localStorage
            const newEmail = form.getFieldValue('new_email');
            setFormData((prevState) => ({
              ...prevState,
              email: newEmail,
            }));
            form.setFieldValue('email', newEmail);
            localStorage.setItem(
              'userDetails',
              JSON.stringify({
                ...formData,
                email: newEmail,
              })
            );
            form.resetFields(['new_email']);
            setEmailEditMode(false);
            setOtp(new Array(6).fill(''));
            setOtpRequested(false); 
          } else {
            const errorData = await response.json();
            message.error(errorData.detail || 'Failed to confirm email change.');
          }
        } catch (error) {
          console.error('Error confirming email change:', error);
          message.error('Error confirming email change.');
        } finally {
          setOtpLoading(false);
        }
      },
    });
  };

  const handleOtpChange = (e, index) => {
    const { value } = e.target;
    if (isNaN(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Focus next input
    if (value && index < otpRefs.current.length - 1) {
      otpRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && index > 0 && !otp[index]) {
      otpRefs.current[index - 1].focus();
    }
  };

  const regenerateOTP = async () => {
    setIsTimerActive(true); // Start the timer again
    setTimer(60); // Reset timer to 60 seconds
    // Simulate OTP regeneration API call
    try {
      const newEmail = form.getFieldValue('new_email');
      if (!newEmail) {
        message.error('Please enter a new email address first.');
        setIsTimerActive(false);
        setTimer(0);
        return;
      }
      const newFormData = new FormData();
      newFormData.append("new_email", newEmail)
      const response = await fetch(`${process.env.REACT_APP_API_URL}/change_email`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${auth.user['access_token']}`,
          // 'Content-Type': 'application/json',
        },
        body: newFormData// JSON.stringify(newFormData),
      });

      if (response.ok) {
        message.success('OTP has been regenerated. Please check your email.');
        setOtpRequested(true); // Ensure OTP fields are visible
      } else {
        const errorData = await response.json();
        message.error(errorData.detail || 'Failed to regenerate OTP.');
        setIsTimerActive(false);
        setTimer(0);
      }
    } catch (error) {
      console.error('Error regenerating OTP:', error);
      message.error('Error regenerating OTP.');
      setIsTimerActive(false);
      setTimer(0);
    }
  };

  return (
    <Row>
      <Col lg={10} md={12} sm={24} xs={24}>
        <div>
          <Title level={2} style={{ marginTop: '0px' }}>
            Profile
          </Title>
          <Divider />
          <Form layout="vertical" form={form} onFinish={handleSubmit}>
            {/* Basic Profile Section */}
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="First Name"
                  name="first_name"
                  rules={[{ required: true, message: 'Please enter your first name!' }]}
                >
                  <Input
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleChange}
                    disabled={!editMode}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Last Name"
                  name="last_name"
                  rules={[{ required: true, message: 'Please enter your last name!' }]}
                >
                  <Input
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleChange}
                    disabled={!editMode}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Organisation Name"
                  name="organisation_name"
                  rules={[{ required: true, message: 'Please enter your organisation name!' }]}
                >
                  <Input
                    name="organisation_name"
                    value={formData.organisation_name}
                    onChange={handleChange}
                    disabled={!editMode}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Contact Number"
                  name="contact_number"
                  rules={[{ required: true, message: 'Please enter your contact number!' }]}
                >
                  <PhoneInput
                    country={'us'}
                    value={formData.contact_number}
                    onChange={(v) =>
                      setFormData((prevState) => ({
                        ...prevState,
                        contact_number: v,
                      }))
                    }
                    inputStyle={{ width: '100%' }}
                    disabled={!editMode}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Contact Address"
                  name="contact_address"
                  rules={[{ required: true, message: 'Please enter your contact address!' }]}
                >
                  <Input.TextArea
                    name="contact_address"
                    value={formData.contact_address}
                    onChange={handleChange}
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    disabled={!editMode}
                  />
                </Form.Item>
              </Col>
            </Row>

            {/* Action Buttons for Basic Profile */}
            {!editMode ? (
              // <Form.Item>
                <Button type="primary" icon={<EditOutlined />} onClick={handleEdit}>
                  Edit Profile
                </Button>
              // </Form.Item>
            ) : (
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  icon={<SaveOutlined />}
                  style={{ marginRight: '10px' }}
                >
                  Update Profile
                </Button>
                <Button onClick={handleCancelEdit}>Cancel</Button>
              </Form.Item>
            )}

            <Divider />

            {/* Email Update Section */}
            <Row gutter={16}>
              <Col span={24}>
                {!emailEditMode ? (
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Form.Item label="Email" name="email" style={{ flex: 1, marginBottom: 0 }}>
                      <Input name="email" value={formData.email} readOnly />
                    </Form.Item>
                    <Button
                      type="link"
                      icon={<EditOutlined />}
                      onClick={() => setEmailEditMode(true)}
                      style={{ marginLeft: '10px', marginTop: '30px' }}
                    >
                      Edit Email
                    </Button>
                  </div>
                ) : (
                  <>
                    <Col span={24}>
                      <Form.Item
                        label="New Email"
                        name="new_email"
                        rules={[
                          { required: true, message: 'Please enter a new email address!' },
                          { type: 'email', message: 'Please enter a valid email!' },
                        ]}
                      >
                        <Input placeholder="Enter new email" />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item>
                        <Button
                          type="primary"
                          loading={emailChangeLoading}
                          onClick={handleRequestEmailChange}
                          icon={<LockOutlined />}
                          style={{ marginRight: '10px' }}
                        >
                          Request OTP
                        </Button>
                        <Button onClick={() => setEmailEditMode(false)}>Cancel</Button>
                      </Form.Item>
                    </Col>
                  </>
                )}
              </Col>

              {/* OTP Section - Shown only after requesting OTP */}
              {emailEditMode && otpRequested && (
                <Col span={24}>
                  <Divider />
                  <Title level={5}>Enter OTP</Title>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                    {otp.map((data, index) => (
                      <Input
                        key={index}
                        ref={(el) => (otpRefs.current[index] = el)}
                        type="text"
                        maxLength="1"
                        value={data}
                        onChange={(e) => handleOtpChange(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        style={{ width: '40px', textAlign: 'center' }}
                      />
                    ))}
                  </div>
                  <Form.Item>
                    <Button
                      type="primary"
                      loading={otpLoading}
                      onClick={handleConfirmEmailChange}
                      icon={<SaveOutlined />}
                      style={{ marginRight: '10px' }}
                    >
                      Change Email
                    </Button>
                    <Button
                      type="default"
                      icon={<ReloadOutlined />}
                      onClick={regenerateOTP}
                      disabled={isTimerActive}
                    >
                      Regenerate OTP
                    </Button>
                  </Form.Item>
                  <div>
                    <Typography.Text>
                      {isTimerActive
                        ? `Resend OTP in ${timer}s`
                        : 'You can now regenerate the OTP.'}
                    </Typography.Text>
                  </div>
                </Col>
              )}
            </Row>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default Profile;
