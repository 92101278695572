import React, { useState, useRef, useEffect } from 'react';
import { Layout, Row, Col, Form, Input, Button, Card, Typography, message } from 'antd';
import AppFooter from '../../AppFooter/AppFooter';
import fullLogo from '../../../assets/images/logo-no-background.png';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../auth/AuthContext';

const { Content } = Layout;
const { Title, Text } = Typography;

const TwoFactorAuthentication = () => {
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [loading, setLoading] = useState(false);
  const [official_email, setOfficialEmail] = useState(localStorage.getItem("userEmail"));
  const [timer, setTimer] = useState(60); // 60 seconds for the countdown timer
  const [isTimerActive, setIsTimerActive] = useState(true); // Disable Regenerate OTP while timer is active
  const otpRefs = useRef([]);
  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    if (isTimerActive && timer > 0) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else if (timer === 0) {
      setIsTimerActive(false); // Enable regenerate button when timer is 0
    }
  }, [timer, isTimerActive]);

  const handleOtpChange = (e, index) => {
    const { value } = e.target;
    if (isNaN(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Focus next input
    if (value && index < otpRefs.current.length - 1) {
      otpRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && index > 0 && !otp[index]) {
      otpRefs.current[index - 1].focus();
    }
  };

  const handleOTPValidation = async () => {
    setLoading(true);
    auth.twoFactorAuthentication(official_email, otp, setLoading);
  };

  const regenerateOTP = async () => {
    setIsTimerActive(true); // Start the timer again
    setTimer(60); // Reset timer to 60 seconds
    // Simulate OTP regeneration API call
    try {
      const username = localStorage.getItem("userName");
      const password = localStorage.getItem("userPassword");
      await auth.login(username, password); 
      message.success('OTP has been regenerated. Please check your email.');
    } catch (error) {
      message.error('Failed to regenerate OTP. Please try again.');
    }
  };

  return (
    <Layout
      style={{
        minHeight: '100vh',
        background: "rgb(255,255,255)",
        background:
          "linear-gradient(207deg, rgba(110,168,255,1) 0%, rgba(255,255,255,1) 20%, rgba(255,255,255,1) 80%, rgba(110,168,255,1) 100%)",
      }}
    >
      <Content style={{ padding: '0', textAlign: 'center' }}>
        <Row>
          <Col span={24} style={{ textAlign: "left", padding: "20px 30px" }}>
            <img src={fullLogo} alt="" width={'150px'} />
          </Col>
        </Row>
        <Row justify="center" align="middle">
          <Col lg={9} md={9} sm={0} xs={0}></Col>
          <Col lg={6} md={6} sm={24} xs={24} style={{ padding: '20px', paddingTop: '10vh' }}>
            <Card
              style={{
                padding: '0px 10px 0px 10px',
                border: "none",
                backgroundColor: 'transparent',
              }}
            >
              <Title style={{ marginTop: '-10px', marginBottom: '50px', color: '#146ef5' }} level={2}>
                Welcome to Sporo Health
              </Title>
              <Title level={3}>Two Factor Authentication</Title>
              <Text>Enter the OTP sent to your email.</Text>
              <Form style={{ textAlign: 'left', marginTop: '20px' }} layout="vertical" onFinish={handleOTPValidation}>
                <Form.Item
                  label="OTP"
                  name="otp"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the OTP sent to your email!',
                    },
                  ]}
                >
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {otp.map((data, index) => (
                      <Input
                        key={index}
                        ref={(el) => (otpRefs.current[index] = el)}
                        type="text"
                        maxLength="1"
                        value={data}
                        onChange={(e) => handleOtpChange(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        style={{ width: '40px', textAlign: 'center', marginRight: '5px' }}
                      />
                    ))}
                  </div>
                </Form.Item>

                <Form.Item>
                  <Button type="primary" style={{ width: '100%' }} htmlType="submit" loading={loading}>
                    Verify
                  </Button>
                </Form.Item>
              </Form>
              <div style={{ marginTop: '20px' }}>
                <Text>{`Resend OTP in ${timer}s`}</Text>
                <Button
                  type="link"
                  disabled={isTimerActive}
                  onClick={regenerateOTP}
                  style={{ paddingLeft: 0, marginLeft: "15px" }}
                >
                  Regenerate OTP
                </Button>
              </div>
              <p className="font-semibold text-muted" style={{ textAlign: 'center', marginTop: "30px", fontSize: "12px", fontWeight: 500 }}>
                <i>For any assistance, feel free to reach out to us at  {' '}
                <Link to="mailto: contact@sporohealth.com" target="_blank">contact@sporohealth.com</Link>
                </i>
              </p>
            </Card>
          </Col>
          <Col lg={9} md={9} sm={0} xs={0}></Col>
        </Row>
        <div style={{ position: "fixed", bottom: 10, width: "100%" }}>
          <AppFooter />
        </div>
      </Content>
    </Layout>
  );
};

export default TwoFactorAuthentication;
