import React, {useState, useEffect, useContext} from 'react';
import { Layout, Row, Col, Card, Button, Divider, List, Avatar, Typography, message, Spin, Badge, Collapse, Select, Flex, Tooltip, Empty, Input, DatePicker, Space, Modal } from 'antd'
import { PlusOutlined, FileTextOutlined, FolderViewOutlined, ProfileOutlined, LoadingOutlined, RedoOutlined, DeleteOutlined, SearchOutlined, CaretRightOutlined } from '@ant-design/icons';
import playIcon from '../../assets/images/play-icon.png'
import Waveform from './Waveform/Waveform';
import {marked} from 'marked';
import DOMPurify from 'dompurify';
import IconButtonGroup from './IconButtonGroup/IconButtonGroup'
import RecordingModal from './RecordingModal/RecordingModal';
import { useAuth } from '../../auth/AuthContext';
import EditPatientModal from '../PatientDetails/EditPatientModal/EditPatientModal';
import AssessmentModal from './AssessmentModal/AssessmentModal';
import PatientNoteModal from './PatientNoteModal/PatientNoteModal';
import StickyChat from './StickyChat/StickyChat';
import ConfirmDeletePatientModal from './ConfirmDeletePatientModal/ConfirmDeletePatientModal'
import ThemeContext from '../../context/ThemeContext';
import '../../assets/css/ModalStyle.css'
// import { data } from '../../Contants';
const { Content } = Layout;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const { Text } = Typography;


const Synopsis = () => {
    const auth = useAuth();
    const { themeMode } = useContext(ThemeContext);
    const [modalVisible, setModalVisible] = useState(false);
    const [finalResponse, setFinalResponse] = useState(null);
    const [data, setData] = useState([]);
    const [patientInterview, setPatientInterview] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);
    const [patientDataLoading, setPatientDataLoading] = useState(null);
    const [patientInterviewLoading, setPatientInterviewLoading] = useState(null);
    const [selectedId, setSelectedId] = useState(null);
    const [selectedVersion, setSelectedVersion] = useState(null);
    const [versionLoading, setVersionLoading] = useState(false);
    const [versions, setVersions] = useState([])
    const [editedSummary, setEditedSummary] = useState(null);
    const [aiGenerated, setAIGenerated] = useState(null);
    const [editSummaryLoading, setEditedSummaryLoading] = useState(false)
    const [activePanel, setActivePanel] = useState('01')
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)
    const [patientId, setPatientId] = useState(null)
    const [sendEmailLoading, setSendEmailLoading] = useState(false)
    const [isAssessmentModalOpen, setIsAssessmentModalOpen] = useState(false)
    const [isPatientNoteModalOpen, setIsPatientNoteModalOpen] = useState(false)
    const [newSubmission, setNewSubmission] = useState(false)
    const [synopsisLoading, setSynopsisLoading] = useState(false);
    const [transcriptionLoading, setTranscriptionLoading] = useState(false);
    const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
    const [deletePatientEntryLoading, setDeletePatientEntryLoading] = useState(false);
    const [searchName, setSearchName] = useState("");
    const [dateRange, setDateRange] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState('en')

    const markdownToHtml = (markdown) => {
        const rawHtml = marked.parse(markdown); // Adjusted to use `parse` method
        return DOMPurify.sanitize(rawHtml);// Sanitize HTML to prevent XSS attacks
    };

    const handleSearch = async () => {
      fetchData(searchName, dateRange);
    };

    const onDateRangeChange = (dates) => {
      setDateRange(dates);
    };

    useEffect(() => {
        fetchData()
    }, [])
    
    useEffect(() => {
        if (selectedId) {            
            setPatientInterview(false)
            setPatientDataLoading(true);
            // setPatientInterviewLoading(true);
            setVersionLoading(true); 
            fetchPatientData()
            // fetchPatientInterview()
            fetchEditedVersions()            
        }
    }, [selectedId])

    useEffect(() => {
      if (newSubmission) {
        setSynopsisLoading(true);
        setTranscriptionLoading(true);
        fetchTranscript()
      }
    }, [newSubmission])

    useEffect(() => {
        if (selectedVersion) {
            setEditedSummaryLoading(true);
            fetchSelectedEditedVersion()
        } else {
          setEditedSummary(null);
          setAIGenerated(null)
          setVersions([]);
        }
    }, [selectedVersion])

    const fetchData = async (searchName = "", dateRange = null) => {
      try {
        const params = new URLSearchParams();

        if (searchName) {
          params.append("name", searchName);
        }

        if (dateRange && dateRange.length === 2) {
          params.append("start_date", dateRange[0].toISOString());
          params.append("end_date", dateRange[1].toISOString());
        }

        const response = await fetch(`${process.env.REACT_APP_API_URL}/files/audio?${params.toString()}`, {
          headers: {
            'Authorization': `Bearer ${auth.user['access_token']}`,
          },
          method: 'GET',
        });

        if (response.ok) {
          const result = await response.json();
          setData(result.data);
          if (result.data.length > 0) {
            setSelectedId(result.data[0]._id);
            setPatientId(result.data[0].patient_id);
          }
        } else {
          message.error('Failed to fetch interview history');
        }
      } catch (error) {
        console.error('Error fetching interview history', error);
        message.error('Error fetching interview history');
      }
      setPageLoading(false);
    };

    const fetchPatientData = async(fromTranscription=false) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/files/audio/${selectedId}`, {
          headers: {
            'Authorization': `Bearer ${auth.user['access_token']}`,
          },
          method: 'GET',
        //   body: formdata,
        });

        if (response.ok) {
          const result = await response.json();      
          setFinalResponse(result);
          setSelectedLanguage(result.target_language_code)
        } else {
          message.error('Failed to fetch interview information');
        }
      } catch (error) {
        console.error('Error fetching interview information', error);
        message.error('Error fetching interview information');
      }
      setPageLoading(false);
      setPatientDataLoading(false);
      fromTranscription && setTranscriptionLoading(false);
    }

    const fetchPatientInterview = async() => {
      // try {
      //   const response = await fetch(`${process.env.REACT_APP_API_URL}/files/audio/${selectedId}/base64`, {
      //     headers: {
      //       'Authorization': `Bearer ${auth.user['access_token']}`,
      //     },
      //     method: 'GET',
      //   });

      //   if (response.ok) {
      //     const result = await response.json();          
      //     setPatientInterview(result);
      //   } else {
      //     message.error('Failed to fetch patient interview');
      //   }
      // } catch (error) {
      //   console.error('Error fetching patient interview', error);
      //   message.error('Error fetching patient interview');
      // }
      setPatientInterview(true);
      setPatientInterviewLoading(false);
    }

    const fetchEditedVersions = async() => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/files/audio/${selectedId}/summary/versions`, {
          headers: {
            'Authorization': `Bearer ${auth.user['access_token']}`,
          },
          method: 'GET',
        });

        if (response.ok) {
          const result = await response.json();          
          if (result.version_history){
            const options = result.version_history.map(item => ({
              label: item.version,
              value: item.version,
            }));
            setVersions(options);
            setSelectedVersion(options[options.length-1].value);
          }else{
            setVersions([]);
            setSelectedVersion(null);
          }
        } else {
          message.error('Failed to fetch Edited Summary History');
        }
      } catch (error) {
        console.error('Error fetching Edited Summary History', error);
        message.error('Error fetching Edited Summary History');
      }
      setVersionLoading(false); 
    }

    const fetchSelectedEditedVersion = async() => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/files/audio/${selectedId}/summary/version/${selectedVersion}`, {
          headers: {
            'Authorization': `Bearer ${auth.user['access_token']}`,
          },
          method: 'GET',
        });

        if (response.ok) {
          const result = await response.json();          
          setEditedSummary(result.summary);
          setAIGenerated(result.ai_generated)
        } else {
          message.error('Failed to fetch Edited Summary');
        }
      } catch (error) {
        console.error('Error fetching Edited Summary', error);
        message.error('Error fetching Edited Summary');
      }
      setEditedSummaryLoading(false);
    }

    const handleSendEmail = async() => {
      setSendEmailLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/files/audio/${selectedId}/send_patient_note`, {
          headers: {
            'Authorization': `Bearer ${auth.user['access_token']}`,
          },
          method: 'POST',
        });

        if (response.ok) {
          const result = await response.json();   
          message.success(result.message);
        } else {
          message.error('Failed to send email');
        }
      } catch (error) {
        console.error('Error sending email', error);
        message.error('Error sending email');
      }
      setSendEmailLoading(false);
    }

    const fetchTranscript = async() => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/v2/files/audio/${selectedId}/generate_transcript`, {
          headers: {
            'Authorization': `Bearer ${auth.user['access_token']}`,
          },
          method: 'POST',
        });

        if (response.ok) {
          const result = await response.json();   
          
          await fetchPatientData(true);
          newSubmission && fetchSummary();
        } else {
          message.error('Failed to generate transcript');
          setNewSubmission(false);
          setTranscriptionLoading(false);
        }
      } catch (error) {
        console.error('Error generating transcript', error);
        message.error('Error generating transcript');
        setNewSubmission(false);
        setTranscriptionLoading(false);
      }
      
    }

    const fetchSummary = async() => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/v2/files/audio/${selectedId}/generate_summary`, {
          headers: {
            'Authorization': `Bearer ${auth.user['access_token']}`,
          },
          method: 'POST',
        });

        if (response.ok) {
          const result = await response.json();   
          
          fetchPatientData();
        } else {
          message.error('Failed to generate summary');
        }
      } catch (error) {
        console.error('Error generating summary', error);
        message.error('Error generating summary');
      }
      setSynopsisLoading(false);
      setNewSubmission(false);
    }

    const handleDeletePatient = async() => {
      setDeletePatientEntryLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/files/audio/delete/${selectedId}`, {
          headers: {
            'Authorization': `Bearer ${auth.user['access_token']}`,
          },
          method: 'DELETE',
        });

        if (response.ok) {
          const result = await response.json();          
          
          message.success("Patient entry deleted successfully");
          fetchData();
        } else {
          message.error('Failed to delete patient entry');
        }
      } catch (error) {
        console.error('Error deleting patient entry', error);
        message.error('Error deleting patient entry');
      }
      setDeletePatientEntryLoading(false);
    }

    // Confirmation for Summary Regeneration
    const confirmRegenerateSummary = () => {
      Modal.confirm({
        title: 'Confirm Regeneration',
        content: 'Do you want to regenerate the summary?',
        okText: 'Yes',
        cancelText: 'No',
        centered: true,
        className: themeMode === 'dark' ? 'dark-modal' : 'light-modal',
        onOk: () => {
          setSynopsisLoading(true);
          fetchSummary();
        },
      });
    };

    // Confirmation for Transcript Regeneration
    const confirmRegenerateTranscript = () => {
      Modal.confirm({
        title: 'Confirm Regeneration',
        content: 'Do you want to regenerate the transcription?',
        okText: 'Yes',
        cancelText: 'No',
        centered: true,
        className: themeMode === 'dark' ? 'dark-modal' : 'light-modal',
        onOk: () => {
          setTranscriptionLoading(true);
          fetchTranscript();
        },
      });
    };

    const confirmFetchInterview = () => {
    Modal.confirm({
      title: 'Confirm Action',
      content: 'Do you want to fetch the interview recording?',
      okText: 'Yes',
      cancelText: 'No',
      centered: true,
      // Optional: If you have themeMode for styling
      className: themeMode === 'dark' ? 'dark-modal' : 'light-modal',
      onOk: () => {
        setPatientInterviewLoading(true);
        fetchPatientInterview(selectedId);
      },
    });
  };

    function replaceUnderscoreWithSpace(name) {
      if (name.includes('_')) {
        return name.replace(/_/g, ' ');
      }
      return name; // If there are no underscores, return the name as is.
    }

    return (
        <Content style={{ overflow: 'initial', maxHeight: "90vh", overflow: "auto" }} className="site-layout-background">

        {pageLoading && versionLoading ? (
            <div
            style={{
                // height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: "20px"
            }}
            >
            <Spin size="large" />
            </div>
            ) : (
            <Row  style={{ height: "820px", display: "flex", padding: "10px"}}>
                <Col lg={6} sm={24} xs={24} style={{padding: "10px", height: "100%", display: "flex", flexDirection: "column" }}>
                    <Card style={{  padding: "10px", height: "100%", display: "flex", flexDirection: "column" }} >
                        <Row>
                            <Col span={24} style={{ height: "720px", overflow: "auto" }}>
                                <div style={{textAlign: "center"}}>
                                  <Button type="primary" shape="round" icon={<PlusOutlined />}  size={"large"} onClick={()=>setModalVisible(true)}> 
                                      Start New Interview
                                  </Button>
                                </div>
                                <Divider style={{marginBottom:'15px'}}></Divider>
                                <Row style={{ marginBottom: '20px' }}>
                                  <Col span={24}>
                                    <Input
                                      placeholder="Search by patient's name"
                                      value={searchName}
                                      onChange={(e) => setSearchName(e.target.value)}
                                      allowClear
                                      style={{ width: '100%' }}
                                    />
                                  </Col>
                                  <Col span={24} style={{marginTop: "10px"}}>
                                    <Space direction="horizontal">
                                      <RangePicker onChange={onDateRangeChange} style={{width: "100%"}} />
                                      <Button type="primary" onClick={handleSearch}><SearchOutlined /></Button>
                                    </Space>
                                  </Col>
                                </Row>
                                <List
                                    pagination={{
                                                position: 'bottom', 
                                                align: 'center',    
                                                pageSize: 6,
                                            }}
                                    dataSource={data}
                                    renderItem={(item, index) => (
                                    <List.Item>
                                        <List.Item.Meta
                                        avatar={<Avatar src={playIcon} />}
                                        title={
                                            <Flex style={{ fontWeight: item._id === selectedId ? 'bold' : 'normal' }}>
                                                {item.name}
                                                <Tooltip placement="top" title="Delete Patient Entry">
                                                  <DeleteOutlined style={{color: "red", marginLeft: "15px", fontSize: "10px"}} onClick={()=>setOpenConfirmDeleteModal(true)} />
                                                </Tooltip>
                                            </Flex>
                                            }
                                            description={<span style={{ fontWeight: item._id === selectedId ? 'bold' : 'normal'}}>{item.upload_time}</span>}
                                        onClick={()=>{setSelectedId(item._id); setPatientId(item.patient_id)}}
                                        style={{ cursor: 'pointer' }}
                                        />
                                        {item._id === selectedId && (
                                            <Badge count="." style={{ backgroundColor: '#00db87', marginLeft: '10px' }} />
                                        )}
                                    </List.Item>
                                    )}
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col lg={18} sm={24} xs={24} style={{padding: "0px 10px", height: "100%", display: "flex", flexDirection: "column" }}>
                {patientDataLoading ? (
                    <div
                    style={{
                        height: '80vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: "20px"
                    }}
                    >
                    <Spin size="large" />
                    </div>
                    ) : (
                    <>
                        <Row style={{ display: "flex", height: "100%" }}>
                        {/* Left Column */}
                        <Col
                          lg={16}
                          sm={24}
                          xs={24}
                          style={{
                            padding: "10px",
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                          }}
                        >
                          {finalResponse && (
                            <>
                              {/* Summary Card */}
                              <Card
                                style={{
                                  padding: "10px",
                                  flex: "1 1 auto",
                                  display: "flex",
                                  flexDirection: "column",
                                  marginBottom: patientInterview ? "10px" : "0px",
                                  // height: "500px",
                                  overflow: "auto",
                                }}
                              >
                                {/* Summary card content */}
                                <Row>
                                  <Col lg={8} md={24} sm={24} xs={24}>
                                    <Flex>
                                      <Typography.Title level={5} style={{ margin: 0 }}>
                                        Summary
                                      </Typography.Title>
                                      <Tooltip placement="top" title="Regenerate Summary">
                                        <Button
                                          shape="circle"
                                          type="default"
                                          icon={<RedoOutlined style={{ fontSize: "12px" }} />}
                                          style={{ marginLeft: "15px", width: "30px", height: "30px" }}
                                          onClick={confirmRegenerateSummary}
                                        />
                                      </Tooltip>
                                    </Flex>
                                  </Col>
                                  <Col lg={16} md={24} sm={24} xs={24} style={{ textAlign: "right" }}>
                                    <Typography.Text style={{ margin: 0, fontSize: "12px", fontWeight: 600 }}>
                                      Selected Template:{" "}
                                      <b style={{ color: "#146ef5" }}>
                                        {finalResponse.template_name || "None Mentioned"}
                                      </b>
                                    </Typography.Text>
                                  </Col>
                                </Row>
                                <Divider style={{ marginTop: "10px" }} />
                                <div style={{ flexGrow: 1 }}>
                                  {/* This area will expand and be scrollable */}
                                  <Collapse
                                    accordion
                                    activeKey={[activePanel]}
                                    onChange={(v) => setActivePanel(v)}
                                    // style={{ maxHeight: patientInterview ? "300px" : "500px", overflow: "auto" }}
                                  >
                                    <Panel header="AI Generated Summary" key="01">
                                      {synopsisLoading ? (
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            padding: "20px",
                                          }}
                                        >
                                          <Spin size="large" />
                                        </div>
                                      ) : (
                                        <>
                                          <Row style={{paddingBottom: "10px"}}>
                                            <Col span={24} style={{ textAlign: "right" }}>
                                              {finalResponse.summary !== null && (
                                                <IconButtonGroup
                                                  text={finalResponse.summary}
                                                  feature={{
                                                    edit: true,
                                                    copy: true,
                                                    download: true,
                                                    email: true,
                                                    fullscreen: true,
                                                  }}
                                                  fileId={selectedId}
                                                  setSelectedVersion={setSelectedVersion}
                                                  fetchEditedVersions={fetchEditedVersions}
                                                  setActivePanel={setActivePanel}
                                                  contentType={"summary"}
                                                />
                                              )}
                                            </Col>
                                          </Row>
                                          <div style={{ maxHeight: patientInterview ? "180px" : "430px", overflow: "auto" }}>
                                            {/* Scrollable content */}
                                            {finalResponse.summary == null ? (
                                              <Empty
                                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                                description={"No Summary Found"}
                                              />
                                            ) : (
                                              <div
                                                className="message-content"
                                                dangerouslySetInnerHTML={{
                                                  __html: markdownToHtml(finalResponse.summary),
                                                }}
                                              />
                                            )}
                                          </div>
                                        </>
                                      )}
                                    </Panel>
                                    {versions.length > 0 && (
                                      <Panel header="Edited Summary" key="02">
                                        <Row style={{paddingBottom: "10px"}}>
                                          <Col lg={8} md={8} sm={24} xs={24}>
                                            <label
                                              style={{
                                                fontSize: "14px",
                                                fontWeight: 600,
                                                marginTop: "5px",
                                                marginRight: "15px",
                                              }}
                                            >
                                              Select Version:{" "}
                                            </label>
                                            <Select
                                              showSearch
                                              placeholder="Select version"
                                              optionFilterProp="label"
                                              onChange={(v) => setSelectedVersion(v)}
                                              value={selectedVersion}
                                              options={versions}
                                              style={{ width: "50%" }}
                                            />
                                          </Col>
                                          <Col lg={8} md={8} sm={24} xs={24}>
                                            <Typography.Text
                                              style={{ margin: 0, fontSize: "12px", fontWeight: 600 }}
                                            >
                                              <b style={{ color: "#146ef5" }}>
                                                {aiGenerated && "Regenerated AI Summary"}
                                              </b>
                                            </Typography.Text>
                                          </Col>
                                          <Col
                                            lg={8}
                                            md={8}
                                            sm={24}
                                            xs={24}
                                            style={{ textAlign: "right" }}
                                          >
                                            <IconButtonGroup
                                              text={editedSummary == null ? "" : editedSummary}
                                              feature={{
                                                edit: true,
                                                copy: true,
                                                download: true,
                                                email: true,
                                                fullscreen: true,
                                              }}
                                              fileId={selectedId}
                                              setSelectedVersion={setSelectedVersion}
                                              fetchEditedVersions={fetchEditedVersions}
                                              setActivePanel={setActivePanel}
                                              versionId={selectedVersion}
                                              contentType={"summary"}
                                            />
                                          </Col>
                                        </Row>
                                        {editSummaryLoading ? (
                                          <div
                                            style={{
                                              justifyContent: "center",
                                              alignItems: "center",
                                              display: "flex",
                                              padding: "20px",
                                            }}
                                          >
                                            <Spin size="large" />
                                          </div>
                                        ) : (
                                          <div style={{ maxHeight: patientInterview ? "180px" : "430px", overflow: "auto" }}>
                                            {/* Scrollable content */}
                                            <div
                                              className="message-content"
                                              dangerouslySetInnerHTML={{
                                                __html: markdownToHtml(
                                                  editedSummary == null ? "" : editedSummary
                                                ),
                                              }}
                                            />
                                          </div>
                                        )}
                                      </Panel>
                                    )}
                                  </Collapse>
                                </div>
                                <Text style={{ fontSize: "10px" }}>
                                  <i>AI can make mistakes. Please share your feedback with us!</i>
                                </Text>
                              </Card>

                              {/* Patient Interview Card */}
                              {patientInterview && (
                                <Card
                                  style={{
                                    padding: "10px",
                                    flex: "1 1 auto",
                                    display: "flex",
                                    flexDirection: "column",
                                    marginTop: "10px",
                                  }}
                                >
                                  {patientInterviewLoading ? (
                                    <div
                                      style={{
                                        alignItems: "center",
                                        justifyContent: "center",
                                        display: "flex",
                                        padding: "20px",
                                        height: "100%",
                                      }}
                                    >
                                      <Spin size="large" />
                                    </div>
                                  ) : (
                                    <>
                                      <Row>
                                        <Col span={12}>
                                          <Typography.Title level={5} style={{ margin: 0, padding: 0 }}>
                                            {replaceUnderscoreWithSpace(
                                              finalResponse.name
                                            )}
                                          </Typography.Title>
                                        </Col>
                                        <Col span={12} style={{ textAlign: "right" }}>
                                          {/* <Typography.Title level={5} style={{ margin: 0, padding: 0 }}>
                                            {finalResponse.audio_length}
                                          </Typography.Title> */}
                                        </Col>
                                      </Row>
                                      <Divider />
                                      <div style={{ flexGrow: 1, overflow: "auto" }}>
                                        <Waveform audioUrl={finalResponse.file_path} />
                                      </div>
                                    </>
                                  )}
                                </Card>
                              )} 
                            </>
                          )}
                        </Col>

                        {/* Right Column */}
                        <Col
                          lg={8}
                          sm={24}
                          xs={24}
                          style={{
                            padding: "10px",
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                          }}
                        >
                          {finalResponse && (
                            <>
                              {/* Transcript Card */}
                              <Card
                                style={{
                                  padding: "10px",
                                  flex: "1 1 auto",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Row>
                                  <Col lg={14} md={14} sm={24} xs={24}>
                                    <Flex>
                                      <Typography.Title level={5} style={{ margin: 0 }}>
                                        Transcript
                                      </Typography.Title>
                                      <Tooltip placement="top" title="Regenerate Transcription">
                                        <Button
                                          shape="circle"
                                          type="default"
                                          icon={<RedoOutlined style={{ fontSize: "12px" }} />}
                                          style={{ marginLeft: "15px", width: "30px", height: "30px" }}
                                          onClick={confirmRegenerateTranscript}
                                        />
                                      </Tooltip>
                                      <Tooltip placement="top" title="Fetch Interview Recording">
                                        <Button
                                          size='small'
                                          shape="circle"
                                          type="default"
                                          icon={<CaretRightOutlined />}
                                          style={{ marginLeft: "15px", backgroundColor: "transparent", color: '#2196F3', borderColor: '#2196F3', marginTop: "3px"}}
                                          onClick={confirmFetchInterview}
                                          loading={patientInterviewLoading}
                                        />
                                      </Tooltip>
                                    </Flex>
                                  </Col>
                                  <Col lg={10} md={10} sm={24} xs={24} style={{ textAlign: "right" }}>
                                    {finalResponse.transcription !== null && (
                                      <IconButtonGroup
                                        text={
                                          finalResponse.transcription[selectedLanguage] == null
                                            ? ""
                                            : finalResponse.transcription[selectedLanguage]
                                        }
                                        feature={{
                                          edit: false,
                                          copy: true,
                                          download: true,
                                          email: false,
                                          fullscreen: true,
                                        }}
                                        fileId={selectedId}
                                        contentType={"transcript"}
                                      />
                                    )}
                                  </Col>
                                </Row>
                                <Divider style={{ margin: 0, marginTop: "5px" }} />
                                <div style={{ flexGrow: 1 }}>
                                  {/* This area will expand and be scrollable */}
                                  <div style={{ height: "450px", overflow: "auto" }}>
                                    {/* Scrollable content */}
                                    {transcriptionLoading ? (
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          padding: "20px",
                                        }}
                                      >
                                        <Spin size="large" />
                                      </div>
                                    ) : finalResponse.transcription == null ? (
                                      <Empty
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        description={"No Transcription Found"}
                                      />
                                    ) : (
                                      <div
                                        className="message-content"
                                        dangerouslySetInnerHTML={{
                                          __html: markdownToHtml(
                                            finalResponse.transcription[selectedLanguage] == null
                                              ? ""
                                              : finalResponse.transcription[selectedLanguage]
                                          ),
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                              </Card>

                              {/* Action Card */}
                              <Card
                                style={{
                                  padding: "10px",
                                  marginTop: "10px",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Row>
                                  <Col span={24}>
                                    <Typography.Title level={5} style={{ margin: 0, padding: 0 }}>
                                      Action
                                    </Typography.Title>
                                  </Col>
                                </Row>
                                <Divider />
                                <Row
                                  style={{ flexGrow: 1, alignItems: "center", justifyContent: "center" }}
                                >
                                  <Col lg={8} sm={24} xs={24} style={{ textAlign: "center" }}>
                                    <Tooltip placement="top" title="Generate Assessment Report">
                                      <Button
                                        type="default"
                                        style={{
                                          height: "50px",
                                          width: "50px",
                                          borderColor: "#0074D9",
                                          marginBottom: "5px",
                                        }}
                                        onClick={() => setIsAssessmentModalOpen(true)}
                                        disabled={!finalResponse.summary}
                                      >
                                        <FileTextOutlined style={{ fontSize: "30px", color: "#0074D9" }} />
                                      </Button>
                                    </Tooltip>
                                  </Col>
                                  <Col lg={8} sm={24} xs={24} style={{ textAlign: "center" }}>
                                    <Tooltip placement="top" title="View Patient Details">
                                      <Button
                                        type="default"
                                        style={{
                                          height: "50px",
                                          width: "50px",
                                          borderColor: "#0074D9",
                                          marginBottom: "5px",
                                        }}
                                        onClick={() => setIsEditModalOpen(true)}
                                      >
                                        <FolderViewOutlined style={{ fontSize: "30px", color: "#0074D9" }} />
                                      </Button>
                                    </Tooltip>
                                  </Col>
                                  <Col lg={8} sm={24} xs={24} style={{ textAlign: "center" }}>
                                    <Tooltip placement="top" title="View Patient Notes">
                                      <Button
                                        type="default"
                                        style={{
                                          height: "50px",
                                          width: "50px",
                                          borderColor: "#0074D9",
                                          marginBottom: "5px",
                                        }}
                                        onClick={() => setIsPatientNoteModalOpen(true)}
                                        disabled={!finalResponse.summary}
                                      >
                                        {sendEmailLoading ? (
                                          <LoadingOutlined style={{ fontSize: "30px", color: "#0074D9" }} />
                                        ) : (
                                          <ProfileOutlined style={{ fontSize: "30px", color: "#0074D9" }} />
                                        )}
                                      </Button>
                                    </Tooltip>
                                  </Col>
                                </Row>
                              </Card>
                            </>
                          )}
                        </Col>
                      </Row>
                    </>)}      
                </Col>
            </Row>)}
            <RecordingModal open={modalVisible} setOpen={setModalVisible} setSelectedId={setSelectedId} fetchData={fetchData} setNewSubmission={setNewSubmission} setSelectedLanguage={setSelectedLanguage} />
            <EditPatientModal open={isEditModalOpen} setOpen={setIsEditModalOpen} patientId={patientId} />
            <AssessmentModal open={isAssessmentModalOpen} setOpen={setIsAssessmentModalOpen} fileId={selectedId} />
            <PatientNoteModal open={isPatientNoteModalOpen} setOpen={setIsPatientNoteModalOpen} fileId={selectedId} handleSendEmail={handleSendEmail} sendEmailLoading={sendEmailLoading}/>
            <ConfirmDeletePatientModal open={openConfirmDeleteModal} setOpen={setOpenConfirmDeleteModal} handleDeletePatient={handleDeletePatient} loading={deletePatientEntryLoading} />
            {(finalResponse && finalResponse.summary) && <StickyChat title="Scribe Chatbot" fileId={selectedId} />}
        </Content>
    )
}

export default Synopsis