// src/pages/Login.tsx
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Layout, Row, Col, Form, Typography, Input, Button, Card, Divider } from 'antd';
import AppFooter from '../AppFooter/AppFooter';
import logo from '../../assets/images/favicon.png';
import fullLogo from '../../assets/images/logo-no-background.png';
import { useAuth } from '../../auth/AuthContext';
import LinkedInLogin from './LinkedInLogin/LinkedInLogin';
import GoogleLogin from './GoogleLogin/GoogleLogin';
import MicrosoftLogin from './MicrosoftLogin/MicrosoftLogin';

const { Content } = Layout;
const { Title } = Typography;

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to the dashboard if the user is already logged in
    if (auth.user) {
      navigate('/synopsis');
    }
  }, [auth.user, navigate]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const newUser = await auth.login(username, password);      
      await auth.fetchUserDetails(newUser.access_token);
      setLoading(false);
      // const firstLogin = newUser.first_login;
      // if (firstLogin) navigate('/change-password');
      if (newUser["2fa_flag"]) {
          localStorage.setItem('userEmail', newUser["email"]);
          localStorage.setItem('userPassword', password);
          localStorage.setItem('userName', username);
          navigate('/twoFactorAuthentication')
          
      } else {
        localStorage.setItem('user', JSON.stringify(newUser));
        navigate('/synopsis');
        auth.setUser(newUser);
      } 
    } catch (error) {
      console.error('Login failed:', error);
      setLoading(false);
    }
  };

  return (
    <Layout style={{ minHeight: '100vh', background: "rgb(255,255,255)", background: "linear-gradient(207deg, rgba(110,168,255,1) 0%, rgba(255,255,255,1) 20%, rgba(255,255,255,1) 80%, rgba(110,168,255,1) 100%)" }}>
      <Content style={{ padding: '0', textAlign: 'center' }}>
        <Row>
          <Col span={24} style={{textAlign: "left", padding: "20px 30px"}}>
          <img src={fullLogo} alt="" width={'150px'} />
          </Col>
        </Row>
        <Row justify="center" align="middle">
          <Col lg={8} md={8} sm ={0} xs={0}></Col>
          <Col lg={8} md={8} sm ={24} xs={24} style={{ padding: '20px', paddingTop: '10vh' }}>
            <Card style={{ padding: '30px', border: "none" }}>
              {/* <img src={logo} alt="" width={'150px'} /> */}
              <Title style={{ marginTop: '-10px', marginBottom: '50px', color: '#146ef5' }} level={2}>
                Welcome to Sporo Scribe AI
              </Title>
              <Form style={{ textAlign: 'left' }} layout="vertical" onFinish={handleSubmit}>
                <Form.Item
                  label="Username or Email Address"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your username!',
                    },
                  ]}
                >
                  <Input placeholder="Username or Email Address" value={username} onChange={(e) => setUsername(e.target.value)} />
                </Form.Item>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                  ]}
                >
                  <Input.Password
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Item>
                <div
                  className="font-semibold text-muted"
                  style={{ textAlign: 'right' }}
                >
                  <Link to="/forgot-password" className="text-dark font-bold">
                    Forgot Password?
                  </Link>
                </div>
                <Form.Item>
                  <Button
                    type="primary"
                    style={{ width: '100%', marginTop: '30px' }}
                    htmlType="submit"
                    loading={loading}
                  >
                    SIGN IN
                  </Button>
                </Form.Item>
                <Divider>OR</Divider>
                <Row style={{ marginTop: '10px', textAlign: "center" }}>
                  <Col span={8} style={{padding: "5px", paddingLeft: 0}}><LinkedInLogin /> </Col>
                  <Col span={8} style={{padding: "5px"}}><MicrosoftLogin /> </Col>
                  <Col span={8} style={{padding: "5px", paddingRight: 0}}><GoogleLogin /> </Col>                       
                </Row>          
              </Form>
            </Card>           
            <p className="font-semibold text-muted" style={{ textAlign: 'center', marginTop: "30px" }}>
              Don't have an account?{' '}
              <Link to="/signup" className="text-dark font-bold">
                Sign Up
              </Link>
            </p>
            <p className="font-semibold text-muted" style={{ textAlign: 'center', marginTop: "30px", fontSize: "12px", fontWeight: 500 }}>
              <i>For any assistance, feel free to reach out to us at  {' '}
              <Link to="mailto: contact@sporohealth.com" target="_blank">contact@sporohealth.com</Link>
              </i>
            </p>
          </Col>
          <Col lg={8} md={8} sm ={0} xs={0}></Col>
        </Row>
      </Content>
      <AppFooter />
    </Layout>
  );
};

export default Login;
